import React, { Component } from "react";
import { Menu, Header } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import commonFunctions from "../../../../commonFunctions";

export default class HomeMenu extends Component {
  state = {
    activeItem: "",
    style_data: {}
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  getStyle(style, hover, exclude = []){

    if(this.props.styles){
      let result = commonFunctions.getStyle(this.props.styles,style,hover,exclude);
      
      if(this.state.hovered == hover){
        return result.finalHover;
      }else{
        return result.finalStyle;
      }
    }

    return {};
    
  }

  render() {
    const { activeItem } = this.state;
    return (
      <Menu className="subMenu" style={this.getStyle("home_menu", `home_menu`)}>
        <Menu.Item>
          <Header>Home Menu</Header>
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to={"/"}
          exact
          name="dash"
          active={activeItem === "dash"}
          onClick={this.handleItemClick}
          style={this.getStyle("home_menu_item", `home_menu_item_dash`)}
          onMouseEnter={() => this.setState({hovered: `home_menu_item_dash`})}
          onMouseLeave={() => this.setState({hovered: ""})}
        >
          Dash
        </Menu.Item>

        <Menu.Item
          as={NavLink}
          to={"/details"}
          exact
          name="details"
          active={activeItem === "details"}
          onClick={this.handleItemClick}
          style={this.getStyle("home_menu_item", `home_menu_item_details`)}
          onMouseEnter={() => this.setState({hovered: `home_menu_item_details`})}
          onMouseLeave={() => this.setState({hovered: ""})}
        >
          Details
        </Menu.Item>
      </Menu>
    );
  }
}
