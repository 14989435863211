import React from "react";
import {
  Table,
  Message,
  Segment,
  Button,
  Icon,
  Label,
  Modal,
  Header,
  Container,
  Confirm,
} from "semantic-ui-react";
import moment from "moment";
import api from "../../../../actions/api";
import { toast } from "react-semantic-toasts";

export default class EmployeeList extends React.PureComponent {

  state = {
    openForgetMeModal: null,
    forgetMeConfirm: false,
    annomisedUsed: false,
    forgetUser: null,
  };
  
  navigateTo(id){
    this.props.action(id)
  }
  
  renderList() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((item) => {
        return (
          <Table.Row key={item._id}>
            <Table.Cell onClick={() => this.navigateTo(item._id)}>{item.ref}</Table.Cell>
            <Table.Cell onClick={() => this.navigateTo(item._id)}>{`${item.firstName} ${item.lastName}`}</Table.Cell>
            <Table.Cell onClick={() => this.navigateTo(item._id)}>{`${item.groups}`}</Table.Cell>
            <Table.Cell onClick={() => this.navigateTo(item._id)}>
              {item.position !== undefined && item.position !== ""
                ? item.position
                : "[UNASSIGNED]"}
            </Table.Cell>
            <Table.Cell onClick={() => this.navigateTo(item._id)}>{item.email}</Table.Cell>
            <Table.Cell>
              {item.primary_benefit ? (
                <Label
                  color={
                    moment(item.primary_benefit.renewal_date).isBefore(
                      moment()
                    ) === true
                      ? "red"
                      : "grey"
                  }
                  key={item.primary_benefit._id}
                >
                  {item.primary_benefit.benefit.title}
                </Label>
              ) : (
                <React.Fragment />
              )}
              {item.secondary_benefit ? (
                <Label
                  color={
                    moment(item.secondary_benefit.renewal_date).isBefore(
                      moment()
                    ) === true
                      ? "red"
                      : "grey"
                  }
                  key={item.secondary_benefit._id}
                >
                  {item.secondary_benefit.benefit.title}
                </Label>
              ) : (
                <React.Fragment />
              )}
              {item.third_benefit ? (
                <Label
                  color={
                    moment(item.third_benefit.renewal_date).isBefore(
                      moment()
                    ) === true
                      ? "red"
                      : "grey"
                  }
                  key={item.third_benefit._id}
                >
                  {item.third_benefit.benefit.title}
                </Label>
              ) : (
                <React.Fragment />
              )}
            </Table.Cell>
            <Table.Cell>
              <Button style={{margin: "5px"}} size="tiny" content="Resend Login" icon="mail" color="orange" onClick={() => {
                api.employee.resendOnboarding(item._id).then((res) => {
                  toast({
                    type: "success",
                    icon: "mail",
                    title: <h3>Onboarding Email Resent</h3>,
                    description: (
                      <span>
                        <p>Successfully resent onboarding email.</p>
                      </span>
                    ),
                    time: 4000,
                  });
                })
              }}/>
              <Button style={{margin: "5px"}} size="tiny" content="Reset Password" icon="lock" color="orange" onClick={() => {
                api.employee.resetPassword(item._id).then((res) => {
                  toast({
                    type: "success",
                    icon: "mail",
                    title: <h3>Employee password reset email sent</h3>,
                    description: (
                      <span>
                        <p>Successfully sent email to employee for password reset.</p>
                      </span>
                    ),
                    time: 4000,
                  });
                })
              }}/>
              {!item.rtbf &&
              <Button style={{margin: "5px"}} size="tiny" content="Forget Me" icon="trash" color="red" onClick={() => {
                this.setState({
                  openForgetMeModal: item._id,
                  forgetUser: item
                })
                //show modal
                /*api.employee.resendOnboarding(item._id).then((res) => {
                  toast({
                    type: "success",
                    icon: "mail",
                    title: <h3>Onboarding Email Resent</h3>,
                    description: (
                      <span>
                        <p>Successfully resent onboarding email.</p>
                      </span>
                    ),
                    time: 4000,
                  });
                }) */
              }}/>}
            </Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  renderForgetMeModal(){

    let {forgetUser} = this.state;

    const handleClose = () => {
      this.setState({ openForgetMeModal: null, forgetMeConfirm: false, annomisedUsed: false, forgetUser: null});

      if(this.state.annomisedUsed) {
        this.props.reloadData();
      }

    };

    return (
      <Modal
        closeIcon
        open={this.state.openForgetMeModal}
        onClose={handleClose}
        centered={false}
        size="large"
        closeOnDimmerClick={!this.state.annomisedUsed && true}
      >
        <Header icon="trash" content="Right to be Forgotten" />
        <Modal.Content>
          <Container basic style={{marginTop: "20px", marginBottom: "20px", marginLeft: "20px", marginRight: "20px"}}>
            {forgetUser &&
            <Message><b>User:</b> {forgetUser.firstName} {forgetUser.lastName} (#{forgetUser.ref}) <br/><b>Email:</b> {forgetUser.email}</Message>}
            <Message info>This process implements the right for the Employee to be forgotten and removed from the database. 
            This process will anonymise everything relating to their account but retain health and medical data for reporting purposes. 
            This data will be anonymous and will not be able to be related to the employee ever again</Message>

            {this.state.annomisedUsed && <Message success>
              <b>This Employee has now been anonymised and removed from the database. Any data has been retained for reporting purposes only.</b>
              </Message>}

            {!this.state.annomisedUsed &&
            <Button
              content="Forget this User"
              onClick={() => this.setState({forgetMeConfirm: true})}
              color="orange"
              floated="right"
            />}

            <Button
              content="Close"
              onClick={() => handleClose()}
            />

            <Confirm
              open={this.state.forgetMeConfirm}
              confirmButton={"Proceed"}
              header={`Proceed to Forget This User`}
              content={
                <Segment basic textAlign={"center"}>
                  <h4>
                    Are you sure you wish to anonymise this employee. This step cannot be undone.
                  </h4>
                </Segment>
              }
              onCancel={() => handleClose()}
              onConfirm={() => {
                api.employee.forgetUser(this.state.openForgetMeModal).then(() => {
                  this.setState({
                    forgetUser: null,
                    forgetMeConfirm: false,
                    annomisedUsed: true
                  });
                });
                
                
              }}
            />

          </Container>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    if (this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>There are no records to display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add New Employee
              </Button>
            )}
          </Segment>
        </Message>
      );
    }
    return (
      <>
      {this.renderForgetMeModal()}
      <Table stackable selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Reference</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Position</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Benefits</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderList()}</Table.Body>
      </Table>
      </>
    );
  }
}
