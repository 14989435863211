import React, { Component } from "react";
import { Grid, Header, Segment, Table, Loader } from "semantic-ui-react";
import Company_Receiver from "../contexts/company_context/Company_Receiver";
import HomeMenu from "../components/menus/homeMenu";
import commonFunctions from "../../../commonFunctions";

class CompanyDetails extends Component {
  state = {
    company: {
      company_address: {},
    },
    data: {},
    style_data: {},
    loading: false,
  };
  componentDidMount() {
    this.props.company._refresh();
    this.getData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.company !== prevProps.company) {
      this.setState({
        company: this.props.company,
        data: this.props.company,
      }, () => {
        this.getData();
      });
    }
  }

  getData(){
    this.setState({loading: true});
    this.setState({style_data: this.props.company.admin_style_data}, () => {
      this.setState({loading: false});
    });
  }

  getStyle(style, hover, exclude = []){

    let result = commonFunctions.getStyle(this.state.style_data,style,hover,exclude);

    if(this.state.hovered == hover){
      return result.finalHover;
    }else{
      return result.finalStyle;
    }
  }

  renderAddress() {
    const { company_address } = this.state.company;

    if(!company_address){
      return <></>
    }

    return (
      <Segment className="border smallPad">
        <Table striped
          style={this.getStyle("home_address_table", `home_address_table`)}
          onMouseEnter={() => this.setState({hovered: `home_address_table`})}
          onMouseLeave={() => this.setState({hovered: ""})}
        >
          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing className="tableHeader">
                Address
              </Table.Cell>
              <Table.Cell>{company_address.address}</Table.Cell>
            </Table.Row>
            {company_address.street && (
              <Table.Row>
                <Table.Cell collapsing className="tableHeader">
                  Street
                </Table.Cell>
                <Table.Cell>{company_address.street}</Table.Cell>
              </Table.Row>
            )}
            {company_address.addressline1 && (
              <Table.Row>
                <Table.Cell collapsing className="tableHeader">
                  Address 1
                </Table.Cell>
                <Table.Cell>{company_address.addressline1}</Table.Cell>
              </Table.Row>
            )}
            {company_address.addressline2 && (
              <Table.Row>
                <Table.Cell collapsing className="tableHeader">
                  Address 2
                </Table.Cell>
                <Table.Cell>{company_address.addressline2}</Table.Cell>
              </Table.Row>
            )}
            {company_address.posttown && (
              <Table.Row>
                <Table.Cell collapsing className="tableHeader">
                  Town
                </Table.Cell>
                <Table.Cell>{company_address.posttown}</Table.Cell>
              </Table.Row>
            )}
            {company_address.county && (
              <Table.Row>
                <Table.Cell collapsing className="tableHeader">
                  County
                </Table.Cell>
                <Table.Cell>{company_address.county}</Table.Cell>
              </Table.Row>
            )}
            {company_address.postcode && (
              <Table.Row>
                <Table.Cell collapsing className="tableHeader">
                  Postcode
                </Table.Cell>
                <Table.Cell>{company_address.postcode}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
  renderDetails() {
    const { company } = this.state;
    return (
      <Segment className="border smallPad">
        <Table striped className="no-marg"
          style={this.getStyle("home_details_table", `home_details_table`)}
          onMouseEnter={() => this.setState({hovered: `home_details_table`})}
          onMouseLeave={() => this.setState({hovered: ""})}
        >
          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing className="tableHeader">
                Telephone
              </Table.Cell>
              <Table.Cell>{company.telephone_number}</Table.Cell>
            </Table.Row>
            {company.email && (
              <Table.Row>
                <Table.Cell collapsing className="tableHeader">
                  Email
                </Table.Cell>
                <Table.Cell>{company.email}</Table.Cell>
              </Table.Row>
            )}
            {company.website && (
              <Table.Row>
                <Table.Cell collapsing className="tableHeader">
                  Website
                </Table.Cell>
                <Table.Cell>{company.website}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
  render() {

    if(this.state.loading){
      return <Loader active indeterminate size="large">
      <h2>Please wait...</h2>
    </Loader>;
    }

    return (
      <Segment basic className="no-pad">
        <HomeMenu styles={this.state.style_data}/>
        <Grid className="no-marg">
          <Grid.Row>
            <Grid.Column width={8}>
              <Header className={"sectionTitle"} style={this.getStyle("home_details", `home_details`)} >Details</Header>
              {this.renderDetails()}
            </Grid.Column>
            <Grid.Column width={8}>
              <Header className={"sectionTitle"} style={this.getStyle("home_address", `home_address`)}>Address</Header>
              {this.renderAddress()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
export default Company_Receiver(CompanyDetails, (company) => {
  return {
    company,
  };
});
