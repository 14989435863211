import React, { Component } from "react";
import {
  Segment,
  Header,
  Menu,
  Modal,
  Button,
  Grid,
  Statistic,
  Icon,
  Loader,
  Message,
  Table,
  Input,
  Select,
  Checkbox,
  Dropdown,
  Label,
  Confirm,
  Form,
} from "semantic-ui-react";
import api from "../../../actions/api";
import { Link } from "react-router-dom";
import PaginateWrapper from "../common/OLD_PaginateWrapper";
import EmployeeList from "../common/lists/employeeList";
import BenefitList from "../common/lists/benefitList";
import EmployeeImporter from "../common/EmployeeImporter";
import ReferralList from "../common/lists/referralList";
import AssessmentList from "../common/lists/assessmentList";
import TestList from "../common/lists/testList";
import AddEmployeeForm from "./forms/addEmployeeForm";
import AssignBenefitForm from "./forms/assignBenefitForm";
import AssignUserBenefits from "./forms/assignUserBenefits";
import NoticeForm from "./forms/noticeForm";
import CompanyNoticeList from "../common/lists/companyNoticeList";
import DraggableTableRow from "../common/draggableTableRow";
import AssignCaseManagers from "../../_cn/components/forms/assignCaseManagers";
import { Can, Authentication } from "../../../lib/sso";
import { toast } from "react-semantic-toasts";
import moment from "moment";
import { DateTimeInput } from "semantic-ui-calendar-react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import commonFunctions from "../../../commonFunctions";

export default class CompanyPage extends Component {
  state = {
    modal_data: {},
    add_test: {},
    data: {},
    error: null,
    company: {
      employees: [],
    },
    dash: {
      emp: 0,
      enr: 0,
      activeRef: 0,
      closedRef: 0,
    },
    current_notice: null,
    benefits: [],
    referrals: [],
    loading: true,
    reload: false,
    assignBenefit: false,
    addEmployee: false,
    showImportModal: false,
    assignBenefitToUser: false,
    addNotice: false,
    emailError: false,
    activeItem: "employees",
    reassignCaseManager: false,
    errors: {},
    assessments: [],
    employees: [],
    form_errors: {},
    filter_employees: "active",
    admin_style_data: {}
  };

  componentDidMount() {
    this.getData();
  }

  getStyle(style, hover, exclude = []){

    let result = commonFunctions.getStyle(this.state.admin_style_data,style,hover,exclude);

    if(this.state.hovered == hover){
      return result.finalHover;
    }else{
      return result.finalStyle;
    }
  }

  getData = () => {
    api.company
      .getCompany(this.props.match.params.id)
      .then((res) => {
        this.setState({
          company: res,
        });
        Promise.all([
          api.company.getDash(this.props.match.params.id),
          api.benefits.all(),
          api.company.getAssessments(this.props.match.params.id),
          api.company.getCompanyEmployeesDropdown(this.props.match.params.id),
          api.company.myCompany()
        ])
          .then((res) => {
            this.setState({
              loading: false,
              reload: false,
              dash: {
                emp: res[0].emp,
                enr: res[0].enr,
                activeRef: res[0].activeRef,
                closedRef: res[0].closedRef,
              },
              benefits: res[1].map((item) => {
                return { text: item.title, value: item._id, key: item._id };
              }),
              assessments: res[2],
              employees: res[3],
              admin_style_data: res[4].admin_style_data
            });
          })
          .catch((e) => {
            this.setState({
              loading: false,
              error:
                e.response !== undefined
                  ? e.response.data.message
                  : "Unknown error while retrieving data from the server has occurred",
            });
          });
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error:
            e.response !== undefined
              ? e.response.data.message
              : "Unknown error while retrieving data from the server has occurred",
        });
      });
  };
  getBenefits = (pageNum, pageSize, search, filters) => {
    return api.company.benefits.getBenefitsPaged(this.props.match.params.id, {
      pageNum,
      pageSize,
      search,
      filters,
    });
  };
  getAssessments = (pageNum, pageSize, search, filters) => {
    return api.company.listAssessmentsPaged(this.props.match.params.id, {
      pageNum,
      pageSize,
      search,
      filters,
    });
  };
  getTests = (pageNum, pageSize, search, filters) => {
    return api.company.listTestsPaged(this.props.match.params.id, {
      pageNum,
      pageSize,
      search,
      filters,
    });
  };
  getReferrals = (pageNum, pageSize, search, filters) => {
    return api.company.listReferralsPaged(this.props.match.params.id, {
      pageNum,
      pageSize,
      search,
      filters,
    });
  };
  getEmployees = (pageNum, pageSize, search, filters) => {
    filters["filtered"] = this.state.filter_employees;
    return api.company.getCompanyEmployeesPaged(this.props.match.params.id, {
      pageNum,
      pageSize,
      search,
      filters,
    });
  };
  getNotices = (pageNum, pageSize, search, filters) => {
    return api.company.notices.listPaged(this.props.match.params.id, {
      pageNum,
      pageSize,
      search,
      filters,
    });
  };

  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });

  onEmployeeSubmit = (data) => {
    this.setState({ reload: true });
    api.company
      .addEmployee(this.props.match.params.id, data)
      .then(() => {
        this.getData();
        this.setState({
          addEmployee: false,
        });
      })
      .finally(() => this.setState({ reload: false }));
  };

  goto = (data) => {
    if (data) {
      this.props.history.push(`/employee/${data}`);
    }
  };

  handleExportUsers = () => {
    // Defining fields that need to be ordered
    let header = [
      { csv_field: "Title", database_field: "title" },
      { csv_field: "Reference", database_field: "ref" },
      { csv_field: "First Name", database_field: "firstName" },
      { csv_field: "Middle Name", database_field: "middleName" },
      { csv_field: "Last Name", database_field: "lastName" },
      { csv_field: "Work Email", database_field: "email" },
      { csv_field: "Date of Birth", database_field: "dob" },
      { csv_field: "Home Email", database_field: "home_email" },
      { csv_field: "Mobile Number", database_field: "mobile_number" },
      { csv_field: "Home Number", database_field: "phone_number" },
      { csv_field: "Gender", database_field: "gender" },
      { csv_field: "Work Number", database_field: "work_number" },
      { csv_field: "Position", database_field: "position" },
      { csv_field: "Archived", database_field: "archived" },
      { csv_field: "employee id", database_field: "employee_id" },
      { csv_field: "ni", database_field: "ni" },
      { csv_field: "Leaver Date", leaver_date: "title" },
    ];

    api.company.getCompanyEmployees(this.props.match.params.id).then((res) => {
      // remapping some data fields
      let items = res.map((item) => {
        let final_item = {
          ...item,
          gender: item.meta ? item.meta.gender : undefined,
          height: item.meta ? item.meta.height : undefined,
          company: item.company ? item.company.name : undefined,
          address: item.address ? item.address.address : undefined,
        };

        delete final_item.meta;

        return final_item;
      });

      // generating csv rows and then downloading
      if (items && items.length !== 0) {
        let csv_rows = [];
        let csv_header = [];
        let csv_header_fields = [];

        header.forEach((item) => {
          csv_header.push(item.csv_field);
          csv_header_fields.push(item.database_field);
        });

        items.forEach((item) => {
          Object.keys(item).forEach((item) => {
            if (
              item !== "_id" &&
              csv_header.find((header) => header === item) === undefined &&
              header.find((header) => header.database_field === item) ===
                undefined
            ) {
              csv_header.push(item);
              csv_header_fields.push(item);
            }
          });
        });

        csv_rows.push(csv_header.join(","));

        items.forEach((item) => {
          csv_rows.push(
            csv_header_fields
              .map((header) => {
                // console.log("🚀 ~ file: company.js ~ line 291 ~ CompanyPage ~ .map ~ header", header)
                let clean_value = (
                  item[header] === undefined ? "" : "" + item[header]
                ).replace(/"|'/g, "");

                if(header === "dob"){
                  clean_value = moment(item[header]).format("DD/MM/YYYY");
                }

                return `"${clean_value}"`;
              })
              .join(",")
          );
              
        });

        let data_blob = new Blob([csv_rows.join("\n")], { type: "text/csv" });
        let a = document.createElement("a");

        a.setAttribute("hidden", "");
        a.setAttribute("href", window.URL.createObjectURL(data_blob));
        a.setAttribute(
          "download",
          `Company Employee Export - ${moment().format("YYYY-MM-DD")}.csv`
        );
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });
  };

  renderEmployeeImportModal() {
    const onClose = (reload = false) => {
      this.setState(
        {
          showImportModal: false,
        },
        () => {
          if (
            this.employeesPaginate !== undefined &&
            this.employeesPaginate !== null &&
            reload
          ) {
            this.employeesPaginate.resetQuery();
          }
        }
      );
    };
    return (
      <Modal centered={false} open={this.state.showImportModal}>
        <Modal.Header>Import Employees</Modal.Header>
        <EmployeeImporter
          onClose={onClose}
          company_id={this.props.match.params.id}
        />
      </Modal>
    );
  }

  renderHeader() {
    const { activeItem } = this.state;
    return (
      <Segment basic className="no-pad no-marg ">
        <Segment className="border">
          <Grid className={"no-marg companyGrid"}>
            <Grid.Row>
              <Grid.Column computer={16} mobile={16}>
                <Statistic.Group widths={3}>
                  <Statistic>
                    <Statistic.Value style={this.getStyle("company_stat", `company_stat_1`)}>{this.state.dash.emp}</Statistic.Value> 
                    <Statistic.Label style={this.getStyle("company_stat_label", `company_stat_label_1`)}>Employees</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value style={this.getStyle("company_stat", `company_stat_2`)}>
                      {this.state.dash.activeRef}
                    </Statistic.Value>
                    <Statistic.Label style={this.getStyle("company_stat_label", `company_stat_label_2`)}>Active Referrals</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value style={this.getStyle("company_stat", `company_stat_3`)}>
                      {this.state.dash.closedRef}
                    </Statistic.Value>
                    <Statistic.Label style={this.getStyle("company_stat_label", `company_stat_label_3`)}>Closed Referrals</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        {this.renderMenu(activeItem)}
      </Segment>
    );
  }

  renderMenu(activeItem) {

    let items = [
      {name: "employees",content: "Employees"},
      {name: "assessments",content: "Health Journeys"},
      {name: "tests",content: "Tests"},
      {name: "referrals",content: "Referrals"},
      {name: "benefits",content: "Benefits"},
      {name: "notices",content: "Notices"},
      {name: "details",content: "Details"},
      {name: "calendar",content: "Calendar"},
      /*Authentication.can("navigator_admin.permission") && {
        name: "add",
        content: <><FaPlus /> Add</>
      },*/
    ];

    let right_items = [
      {name: "settings",content: <><Icon name="cogs" /> Company Settings</>},
      {name: "edit",content: <><Icon name="pencil" /> Edit Company</>},
    ];

    return (
      <Menu className="subMenu" style={this.getStyle("company_menu", `company_menu`)}>

        {items.map((item, index) => {
          return <Menu.Item
            name={item.name}
            active={activeItem === item.name}
            onClick={this.handleMenuClick}

            style={this.getStyle("company_menu_item", `company_menu_item_`+index)}
            onMouseEnter={() => this.setState({hovered: `company_menu_item_`+index})}
            onMouseLeave={() => this.setState({hovered: ""})}

          >
            {item.content}
          </Menu.Item>
        })}
        {/* <Menu.Item
          name="finance"
          active={activeItem === "finance"}
          onClick={this.handleMenuClick}
        >
          Money stuff
        </Menu.Item> */}
        <Menu.Menu position="right">

          {right_items.map((item, index) => {
            return <Can has={"company.edit"}>
            <Menu.Item
              as={Link}
              to={`/company/${this.props.match.params.id}/`+item.name}
              style={this.getStyle("company_menu_item", `company_menu_item_right_${index}`)}
              onMouseEnter={() => this.setState({hovered: `company_menu_item_right_`+index})}
              onMouseLeave={() => this.setState({hovered: ""})}
            >
              {item.content}
              
            </Menu.Item>
          </Can>
          })}
        </Menu.Menu>
      </Menu>
    );
  }

  renderEmployees() {
    if (this.state.loading) {
      return null;
    }
    return (
      <Segment basic>
        <Can has={"employee.add"}>
          <Button positive onClick={() => this.setState({ addEmployee: true })}>
            <Icon name="user" /> Add Employee
          </Button>
          <Can has={"company.employee_import"}>
            <Button
              primary
              onClick={() => this.setState({ showImportModal: true })}
            >
              <Icon name="user" /> Import
            </Button>
          </Can>
          <Can has={"company.employee_import"}>
            <Button primary onClick={() => this.handleExportUsers()}>
              <Icon name="download" /> Export
            </Button>
          </Can>
        </Can>
        <Button.Group style={{ paddingLeft: "15%", float: "right" }}>
          <Button
            content={`Archived`}
            negative={this.state.filter_employees === "archived"}
            onClick={() =>
              this.setState(
                {
                  filter_employees: "archived",
                },
                () => {
                  this.getData();
                }
              )
            }
          />
          <Button
            content={`Anonymised`}
            color={this.state.filter_employees === "anonymised" && "orange"}
            onClick={() =>
              this.setState(
                {
                  filter_employees: "anonymised",
                },
                () => {
                  this.getData();
                }
              )
            }
          />
          <Button
            content={`Leavers`}
            color={this.state.filter_employees === "leavers" && "blue"}
            onClick={() =>
              this.setState(
                {
                  filter_employees: "leavers",
                },
                () => {
                  this.getData();
                }
              )
            }
          />
          <Button
            content={`Active`}
            positive={this.state.filter_employees === "active"}
            onClick={() =>
              this.setState(
                {
                  filter_employees: "active",
                },
                () => {
                  this.getData();
                }
              )
            }
          />
        </Button.Group>
        {this.renderEmployeeImportModal()}
        {this.state.reload === false ? (
          <PaginateWrapper
            ref={(ref) => (this.employeesPaginate = ref)}
            dataQuery={(pageNum, pageSize, search, filters) => this.getEmployees(pageNum, pageSize, search, filters)}
            search={true}
            render={(items) => <EmployeeList data={items} reloadData={() => {
              this.setState({ reload: true });
              this.getData();
            }} action={this.goto} />}
          />
        ) : (
          <Segment basic>
            <Loader active />
          </Segment>
        )}
      </Segment>
    );
  }

  renderCalendar() {
    const { company } = this.state;

    const localizer = momentLocalizer(moment)

    const events = [
      {
        id: 0,
        title: 'Eliot - Available',
        start: new Date(2024, 3, 18, 9, 0, 0),
        end: new Date(2024, 3, 18, 9, 30, 0),
        resourceId: 1,
      },
      {
        id: 1,
        title: 'Eliot - Available',
        start: new Date(2024, 3, 18, 9, 30, 0),
        end: new Date(2024, 3, 18, 10, 0, 0),
        resourceId: 1,
      },
      {
        id: 2,
        title: 'Eliot - Meeting with Stu',
        start: new Date(2024, 3, 18, 10, 0, 0),
        end: new Date(2024, 3, 18, 10, 30, 0),
        resourceId: 1,
      },
      {
        id: 3,
        title: 'Stu - Available',
        allDay: true,
        start: new Date(2024, 3, 29, 14, 0, 0),
        end: new Date(2024, 3, 29, 16, 30, 0),
        resourceId: 2,
      },
    ];

    return (
      <Segment>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          // onSelectSlot={handleSelectSlot}
          style={{ height: 500 }}
        />
      </Segment>
    );
  }

  renderDetails() {
    const { company } = this.state;
    return (
      <Segment>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column className="addressCol">
              <Grid className="no-marg dataGrid " celled>
                <Grid.Row>
                  <Grid.Column>
                    <Header>Address Line 1:</Header>
                    <span>
                      {company.company_address &&
                        company.company_address.addressline1 + ", "}
                    </span>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Header>Address Line 2:</Header>
                    <span>
                      {company.company_address &&
                        company.company_address.addressline2 + ", "}
                    </span>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Header>Street:</Header>
                    <span>
                      {company.company_address &&
                        company.company_address.street + ", "}
                    </span>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Header>City:</Header>
                    <span>
                      {company.company_address &&
                        company.company_address.posttown + ", "}
                    </span>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Header>County:</Header>
                    <span>
                      {company.company_address &&
                        company.company_address.county + ", "}
                    </span>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Header>Postcode:</Header>
                    <span>
                      {company.company_address &&
                        company.company_address.postcode + ", "}
                    </span>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            {/* <Grid.Column>
              <Header as={"h1"}>About</Header>
              <Header as={"h4"}>{company.company_info ? company.company_info : "--"}</Header>
            </Grid.Column> */}
            <Grid.Column>
              <Grid className="no-marg dataGrid " celled>
                <Grid.Row>
                  <Grid.Column>
                    <Header>Email:</Header>
                    <span>{company.email}</span>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Header>Website:</Header>
                    <span>{company.website}</span>
                  </Grid.Column>
                </Grid.Row>
                {/* <Grid.Row>
                  <Grid.Column>
                    <Header>Contact Type:</Header>
                    <span>
                      Email:{" "}
                      {company.contact_types && company.contact_types.email ? (
                        <Icon name="check" color="green" />
                      ) : (
                        <Icon name="close" color="red" />
                      )}
                      Phone:{" "}
                      {company.contact_types && company.contact_types.phone ? (
                        <Icon name="check" color="green" />
                      ) : (
                        <Icon name="close" color="red" />
                      )}
                      Post:{" "}
                      {company.contact_types && company.contact_types.post ? (
                        <Icon name="check" color="green" />
                      ) : (
                        <Icon name="close" color="red" />
                      )}
                    </span>
                  </Grid.Column>
                </Grid.Row> */}
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  renderBenefits() {
    return (
      <Segment basic>
        <Can has={"company.benefit.employee.assign"}>
          <Button
            positive
            onClick={() => this.setState({ assignBenefitToUser: true })}
          >
            <Icon name="clone" /> Assign To Staff
          </Button>
        </Can>
        {this.state.reload === false ? (
          <PaginateWrapper
            dataQuery={this.getBenefits}
            render={(items) => (
              <BenefitList
                data={items}
                add={() => this.setState({ assignBenefit: true })}
                action={(data) =>
                  this.props.history.push(
                    `/company/${this.props.match.params.id}/benefit/${data}`
                  )
                }
                allowAdd={true}
              />
            )}
          />
        ) : (
          <Segment basic>
            <Loader active />
          </Segment>
        )}
      </Segment>
    );
  }

  renderNotices() {
    return (
      <Segment basic>
        {this.state.reload === false ? (
          <PaginateWrapper
            dataQuery={this.getNotices}
            active={"Archived"}
            render={(items) => (
              <CompanyNoticeList
                data={items}
                add={() => this.setState({ addNotice: true })}
                action={(data) => this.setState({ current_notice: data })}
                allowAdd={true}
              />
            )}
          />
        ) : (
          <Segment basic>
            <Loader active />
          </Segment>
        )}
      </Segment>
    );
  }

  renderReassignCaseManagers() {
    const handleClose = () => this.setState({ reassignCaseManager: false });

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      api.case
        .assignMultiCaseManager(data)
        .then((res) => {
          this.getData();
          handleClose();
          this.setState({ reload: false });
        })
        .catch((e) => {
          this.setState({ reload: false });
        });
    };

    return (
      <Modal
        open={this.state.reassignCaseManager}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header
          icon="clone"
          content="ReAssigning referral(s) to company referral manager"
        />
        <Modal.Content>
          <AssignCaseManagers
            close={handleClose}
            submit={handleSubmit}
            reassign={true}
            referrals={this.getReferrals}
            company={this.state.company._id}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderAssessmentAddModal() {
    const handleClose = () => this.setState({ addAssessmentModal: false });

    const addAssessment = () => {
      api.assessment
        .addAssessment(this.state.modal_data.patient)
        .then((res) => {
          this.props.history.push(`/assessment/${res.id}`);
        });
    };

    return (
      <Modal
        open={this.state.addAssessmentModal}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="file" content="Add Health Journey" />
        <Modal.Content>
          <Segment>
            <Form>
              <Form.Group widths="equal">
                <Form.Dropdown
                  name="patient"
                  label="Client"
                  value={this.state.modal_data.patient}
                  onChange={(e, data) => {
                    this.setState({
                      modal_data: {
                        ...this.state.modal_data,
                        [data.name]: data.value,
                      },
                    });
                  }}
                  placeholder="Please Select Client for new Health Journey"
                  options={this.state.employees.map((employee) => {
                    return {
                      value: employee.value,
                      key: employee.key,
                      text: `${employee.text} (${employee.ref && `#${employee.ref}`} | ${employee.dob && moment(employee.dob).format("DD/MM/YYYY")})`,
                    };
                  })}
                  
                  selection
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Button
                  type="submit"
                  color="red"
                  floated="left"
                  content="Cancel"
                  icon="close"
                  onClick={() => handleClose()}
                />
                <Form.Button
                  disabled={!this.state.modal_data.patient}
                  color="green"
                  floated="right"
                  content="Add"
                  icon="plus"
                  onClick={() => addAssessment()}
                />
              </Form.Group>
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  renderTestAddModal() {
    const handleClose = () => this.setState({ addTestModal: false });

    const addTest = () => {
      //barcode: /^5000[0-9]{9}$/'

      let barcode = this.state.add_test.barcode;

      if (barcode && barcode.match(/^5000[0-9]{9}$/)) {
        this.state.form_errors.barcode = false;
        api.company
          .addTest(this.props.match.params.id, {
            ...this.state.add_test,
            employee: this.state.add_test.patient,
          })
          .then((res) => {
            // console.log("🚀 ~ file: company.js ~ line 1234 ~ CompanyPage ~ .then ~ res", res)
            if (res.success) {
              this.setState({
                addTestSuccessful: true,
                addTestSubmitted: false,
                add_test: {},
              activeItem: "assessments",
              });
            } else {
              this.setState({
                addTestSuccessful: false,
                addTestSubmitted: true,
                addTestError: res.error,
              });
            }
          })
          .catch((e) => {
            console.log(
              "🚀 ~ file: company.js ~ line 790 ~ CompanyPage ~ handleSubmit ~ e",
              e
            );
          });
      } else {
        let form_errors = { ...this.state.form_errors, barcode: true };
        this.setState({ form_errors });
      }
    };

    function objToString(obj) {
      return Object.entries(obj).reduce((str, [p, val]) => {
        return `${str}${p}: ${val}\n`;
      }, "");
    }

    return (
      <Modal
        open={this.state.addTestModal}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="medkit" content="Submit Test- " />
        <Modal.Content>
          {this.state.addTestSuccessful ? (
            <Segment placeholder>
              <Header icon>
                <Icon name="check circle outline" color="green" />
                Your test has been sent for the Lab to process. Check for the
                Results using Collect Results button <br /> <br />
                <Button onClick={handleClose} content="Close" />
              </Header>
            </Segment>
          ) : this.state.addTestSubmitted ? (
            this.state.addTestError ? (
              <Segment placeholder>
                <Header icon>
                  <Icon name="exclamation triangle" color="red" />
                  Error: Their was an error submitting your test! <br /> <br />{" "}
                  {objToString(this.state.addTestError)} <br /> <br />
                  <Button
                    onClick={() => {
                      this.setState({
                        addTestSubmitted: false,
                        addTestError: {},
                      });
                    }}
                    content="Try Again"
                  />
                </Header>
              </Segment>
            ) : (
              <Segment placeholder>
                <Header icon>
                  <Icon loading name="circle notch" color="blue" />
                  Submitting your Test to the lab, please wait for a response on
                  if the submission was successful or if their was an error.
                </Header>
              </Segment>
            )
          ) : (
            <Segment>
              <Form>
                <Form.Dropdown
                  name="patient"
                  label="Patient"
                  value={this.state.add_test.patient}
                  onChange={(e, data) => {
                    this.setState({
                      add_test: {
                        ...this.state.add_test,
                        [data.name]: data.value,
                      },
                    });
                  }}
                  placeholder="Please Select Patient for new Test"
                  options={this.state.employees.map((employee) => {
                    return {
                      ...employee,
                      text: `${employee.text} (${employee.ref && `#${employee.ref}`} | ${employee.dob && moment(employee.dob).format("DD/MM/YYYY")})`,
                      
                    };
                  })}
                  selection
                />
                <Form.Input
                  label="Barcode"
                  name="barcode"
                  error={
                    this.state.form_errors.barcode &&
                    "Incorrect format of barcode! Example of correct format: 5000008797150"
                  }
                  placeholder={"5000008797150"}
                  value={this.state.add_test.barcode}
                  onChange={(event, { name, value }) => {
                    this.setState({
                      add_test: { ...this.state.add_test, [name]: value },
                    });
                  }}
                />
                <DateTimeInput
                  label="Sample Taken"
                  autoComplete="off"
                  animation="off"
                  name="taken"
                  popupPosition={"bottom left"}
                  dateTimeFormat="YYYY/MM/DD HH:mm"
                  dateFormat="YYYY/MM/DD"
                  closable={true}
                  disableMinute={false}
                  placeholder="Select Date"
                  value={
                    this.state.add_test.taken
                      ? moment(this.state.add_test.taken).format(
                          "YYYY/MM/DD HH:mm"
                        )
                      : ""
                  }
                  iconPosition="left"
                  onChange={(e, data) => {
                    this.setState({
                      add_test: {
                        ...this.state.add_test,
                        [data.name]: data.value,
                      },
                    });
                  }}
                />
                <Form.Dropdown
                  label="Gender"
                  placeholder="Select Gender"
                  name="gender"
                  value={this.state.add_test.gender}
                  onChange={(e, data) => {
                    this.setState({
                      add_test: {
                        ...this.state.add_test,
                        [data.name]: data.value,
                      },
                    });
                  }}
                  options={
                    this.state.company && this.state.company.genders
                      ? this.state.company.genders.map((item) => {
                          return { text: item.description, value: item.id };
                        })
                      : []
                  }
                  search
                  selection
                  // error={this.state.errors.groups}
                />
                <Form.Dropdown
                  label="Test Type"
                  placeholder="Select Test Type"
                  name="type"
                  value={this.state.add_test.type}
                  onChange={(e, data) => {
                    this.setState({
                      add_test: {
                        ...this.state.add_test,
                        [data.name]: data.value,
                      },
                    });
                  }}
                  options={
                    this.state.company && this.state.company.sampleTypes
                      ? this.state.company.sampleTypes.map((item) => {
                          return { text: item.name, value: item.id };
                        })
                      : []
                  }
                  search
                  selection
                  // error={this.state.errors.groups}
                />
                <Form.Dropdown
                  label="Specimen Type"
                  placeholder="Select Specimen"
                  name="specific"
                  value={this.state.add_test.specific}
                  onChange={(e, data) => {
                    this.setState({
                      add_test: {
                        ...this.state.add_test,
                        [data.name]: data.value,
                      },
                    });
                  }}
                  options={
                    this.state.company && this.state.company.specifics
                      ? this.state.company.specifics.map((item) => {
                          return { text: item.name, value: item.id };
                        })
                      : []
                  }
                  search
                  selection
                  // error={this.state.errors.groups}
                />
                <Form.Group widths="equal">
                  <Form.Button floated="left" onClick={handleClose} negative>
                    <Icon name="close" />
                    Cancel
                  </Form.Button>
                  <Form.Button
                    floated="right"
                    onClick={addTest}
                    color="green"
                    disabled={
                      !this.state.add_test.patient ||
                      !this.state.add_test.barcode ||
                      !this.state.add_test.taken ||
                      !this.state.add_test.type ||
                      !this.state.add_test.specific
                    }
                  >
                    <Icon name="plus" />
                    Submit
                  </Form.Button>
                </Form.Group>
              </Form>
            </Segment>
          )}
        </Modal.Content>
      </Modal>
    );
  }

  renderAssessments() {
    return (
      <Segment basic>
        <PaginateWrapper
          dataQuery={this.getAssessments}
          render={(items) => (
            <AssessmentList
              allowAdd
              add={() => this.setState({ addAssessmentModal: true })}
              data={items}
              action={(data) => {
                if (data) {
                  this.props.history.push(`/assessment/${data}`);
                }
              }}
              showCM={true}
            />
          )}
        />
      </Segment>
    );
  }

  renderTests() {
    return (
      <Segment basic>
        {this.renderAddTest()}
        {/*  
        <Button positive onClick={() => this.setState({ addTest: true })}>
          <Icon name="file" /> Add Test
        </Button>
        <Button
          color="orange"
          onClick={() => this.setState({ addResult: true })}
        >
          <Icon name="file" /> Collect/View Results
        </Button>*/}
        <PaginateWrapper
          dataQuery={this.getTests}
          render={(items) => (
            <TestList
              allowAdd
              add={() => this.setState({ addTestModal: true })}
              data={items}
              action={(data) => {
                if (data) {
                  this.props.history.push(`/assessment/${data}`);
                }
              }}
              showCM={true}
            />
          )}
        />
      </Segment>
    );
  }

  renderAddTest() {
    const handleClose = () => {
      if (this.state.addTestSuccessful) {
        this.setState({ activeItem: "assessments" });
      }

      this.setState({ addTest: false, addTestSuccessful: false });
    };

    const handleSubmit = () => {
      let data = this.state.data;
      api.company
        .addAssessmentTest(this.props.match.params.id, {
          assessment: data.test_assessment,
          type: data.test_type,
        })
        .then((res) => {
          // console.log("🚀 ~ file: company.js ~ line 1234 ~ CompanyPage ~ .then ~ res", res)
          if (res.success) {
            this.setState({
              addTestSuccessful: true,
              addTestSubmitted: false,
              data: {
                ...this.state.data,
                test_assessment: null,
                test_type: null,
              },
            });
          } else {
            this.setState({
              addTestError: res.error,
            });
          }
        })
        .catch((e) => {
          console.log(
            "🚀 ~ file: company.js ~ line 790 ~ CompanyPage ~ handleSubmit ~ e",
            e
          );
        });
    };

    return (
      <Modal
        open={this.state.addTest}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="medkit" content="Add Test" />
        <Modal.Content>
          {/*  
          <AddEmployeeForm
            submit={handleSubmit}
            close={handleClose}
            emailError={this.state.emailError}
          />*/}
          {this.state.addTestSuccessful ? (
            <Segment placeholder>
              <Header icon>
                <Icon name="check circle outline" color="green" />
                Your test has been sent for the Lab to process. Check for the
                Results using Collect Results button
                <Button onClick={handleClose} content="Close" />
              </Header>
            </Segment>
          ) : (
            <Segment>
              <Form>
                <Form.Dropdown
                  label="Assessment"
                  placeholder="Select an assessment"
                  name="test_assessment"
                  value={this.state.data.test_assessment}
                  onChange={this.handleDropdownChange}
                  options={
                    this.state.assessments &&
                    this.state.assessments.map((item) => {
                      return {
                        value: item._id,
                        text: `#${item.assNum} (${item.employee.firstName} ${item.employee.lastName})`,
                      };
                    })
                  }
                  search
                  selection
                  // error={this.state.errors.groups}
                />
                <Form.Dropdown
                  label="Sample Type"
                  placeholder="Select test type"
                  name="test_type"
                  value={this.state.data.test_type}
                  onChange={this.handleDropdownChange}
                  options={
                    this.state.company && this.state.company.sampleTypes
                      ? this.state.company.sampleTypes.map((item) => {
                          return { text: item, value: item };
                        })
                      : []
                  }
                  search
                  selection
                  // error={this.state.errors.groups}
                />
                <Form.Group widths="equal">
                  <Form.Button floated="left" onClick={handleClose} negative>
                    <Icon name="close" />
                    Cancel
                  </Form.Button>
                  <Form.Button
                    floated="right"
                    onClick={handleSubmit}
                    color="green"
                    disabled={
                      !this.state.data.test_assessment ||
                      !this.state.data.test_type
                    }
                  >
                    <Icon name="plus" />
                    Submit
                  </Form.Button>
                </Form.Group>
              </Form>
            </Segment>
          )}
        </Modal.Content>
      </Modal>
    );
  }

  renderReferrals() {
    return (
      <Segment basic>
        <Can has={"referral.reassign"}>
          <Button
            // floated="right"
            color="orange"
            onClick={() => this.setState({ reassignCaseManager: true })}
          >
            <Icon name="clone" /> ReAssign To Referral Manager
          </Button>
        </Can>

        <PaginateWrapper
          dataQuery={this.getReferrals}
          render={(items) => (
            <ReferralList
              data={items}
              action={(data) => {
                if (data) {
                  this.props.history.push(`/referral/${data}`);
                }
              }}
              showCM={true}
            />
          )}
        />
      </Segment>
    );
  }

  renderAddEmployee() {
    const handleClose = () => this.setState({ addEmployee: false });

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      api.company
        .addEmployee(this.props.match.params.id, data)
        .then(() => {
          this.getData();
          this.setState({
            addEmployee: false,
            emailError: false,
          });
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.message === "Dupe email not allowed."
          ) {
            this.setState({ emailError: true });
          }
        })
        .finally(() => this.setState({ reload: false }));
    };

    return (
      <Modal
        open={this.state.addEmployee}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="user" content="Add Employee" />
        <Modal.Content>
          <AddEmployeeForm
            submit={handleSubmit}
            close={handleClose}
            emailError={this.state.emailError}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderAssignBenefit() {
    const handleClose = () => this.setState({ assignBenefit: false });

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      api.company.benefits
        .assignBenefit(this.props.match.params.id, data)
        .then(() => {
          this.getData();
          handleClose();
          this.setState({ reload: false });
        })
        .catch((e) => {
          this.setState({ reload: false });
        });
    };

    return (
      <Modal
        open={this.state.assignBenefit}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="linkify" content="Assign Benefit" />
        <Modal.Content>
          <AssignBenefitForm
            close={handleClose}
            submit={handleSubmit}
            benefits={this.state.benefits}
          />
        </Modal.Content>
      </Modal>
    );
  }

  renderAssignBenefitToUser() {
    const handleClose = () => this.setState({ assignBenefitToUser: false });

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      api.company.benefits
        .assignBenefitToUser(this.props.match.params.id, data)
        .then(() => {
          this.getData();
          handleClose();
          this.setState({ reload: false });
        })
        .catch((e) => {
          this.setState({ reload: false });
        });
    };

    return (
      <Modal
        open={this.state.assignBenefitToUser}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="linkify" content="Assigning Benefit(s) To Employee(s)" />
        <Modal.Content>
          <AssignUserBenefits
            close={handleClose}
            submit={handleSubmit}
            company={this.state.company && this.state.company._id}
          />
        </Modal.Content>
      </Modal>
    );
  }

  handleDropdownChange = (e, { value, name }) =>
    this.setState({ data: { ...this.state.data, [name]: value } });

  renderNotice() {
    const handleClose = () => {
      if (this.state.current_notice === null) {
        this.setState({ addNotice: false });
      } else {
        this.setState({ current_notice: null });
      }
    };

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      if (this.state.current_notice === null) {
        api.company.notices
          .add(this.props.match.params.id, data)
          .then(() => {
            this.getData();
            this.setState({
              addNotice: false,
            });
          })
          .finally(() => this.setState({ reload: false }));
      } else {
        api.company.notices
          .edit(this.props.match.params.id, data._id, data)
          .then(() => {
            this.getData();
            this.setState({
              current_notice: null,
            });
          })
          .finally(() => this.setState({ reload: false }));
      }
    };

    return (
      <Modal
        open={this.state.addNotice || this.state.current_notice !== null}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header
          icon="user"
          content={`${
            this.state.current_notice === null ? "Add" : "Edit"
          } Notice`}
        />
        <Modal.Content>
          <NoticeForm
            submit={handleSubmit}
            close={handleClose}
            data={this.state.current_notice}
          />
        </Modal.Content>
      </Modal>
    );
  }
  render() {
    const { activeItem, company, error } = this.state;
    if (error !== null) {
      return (
        <Message negative>
          <Message.Header>ERROR</Message.Header>
          {error}
        </Message>
      );
    }
    return (
      <Segment loading={this.state.loading} basic className="no-pad">
        <Header as={"h1"} textAlign="center" className="no-marg pageTitle main" style={this.getStyle("company_header", `company_header`)}>
          {company.name}
        </Header>
        <Segment basic className="no-hpad">
          {this.renderAssignBenefit()}
          {this.renderAddEmployee()}
          {this.renderNotice()}
          {this.renderAssignBenefitToUser()}
          {this.renderHeader()}

          {this.renderAssessmentAddModal()}
          {this.renderTestAddModal()}
          <Segment className="no-pad">
            {activeItem === "employees" && this.renderEmployees()}
            {activeItem === "details" && this.renderDetails()}
            {activeItem === "benefits" && this.renderBenefits()}
            {/* {activeItem === "app_actions" && this.renderAPPActions()} */}

            {activeItem === "assessments" && this.renderAssessments()}
            {activeItem === "tests" && this.renderTests()}
            {activeItem === "referrals" &&
              // !Authentication.can("organisation_case_manager.permission") &&
              this.renderReferrals()}
            {activeItem === "notices" && this.renderNotices()}
            {activeItem === "calendar" && this.renderCalendar()}
          </Segment>
        </Segment>
        {Authentication.can("organisation_case_manager.permission") &&
          this.renderReassignCaseManagers()}
      </Segment>
    );
  }
}
