import { useEffect } from 'react';
import { useDyteClient, useDyteMeeting, DyteProvider } from '@dytesdk/react-web-core';
import { DyteMeeting } from '@dytesdk/react-ui-kit';

const MeetingViewer = () => {
    const { meeting } = useDyteMeeting();
    console.log("meeting a", meeting);

  return (
    <div style={{ height: '700px' }}>
      <DyteMeeting 
        mode="fill" 
        meeting={meeting} 
        showSetupScreen={false} 
      />
    </div>
    );
}

const MeetingComponent = () => {
  const [meeting, initMeeting] = useDyteClient();

  if(meeting){
    meeting.self.setName("PA Admin");
  }

  let auth = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdJZCI6ImYxZDFjMTU5LWQ0ZGYtNDgzYS05OGRhLTc4NmUxMmMyMTFhNyIsIm1lZXRpbmdJZCI6ImJiYjI1M2Y0LTNhNzAtNDllYi1hNDNkLTBkZmU5YjcyYzFhZiIsInBhcnRpY2lwYW50SWQiOiJhYWFiMDc2OC1lMzAyLTQ4ZmItOGZkOC1hODI2ZTUxNWVhN2IiLCJwcmVzZXRJZCI6IjMwN2NhMjZlLTQzOWUtNGNmYi1iMzllLWQ4ZmJkMDA4YTFhYiIsImlhdCI6MTcxMzM4OTI5OCwiZXhwIjoxNzIyMDI5Mjk4fQ.B_um5nms_MvY3lFQc7EmPtyvkT7VK2ghmldCYIBSytMQgcsBMfvrsJXKPsUrhPtfyD_pfx0jB485wuL8EY1TXcsI_gyTUddDHCdkcVdwDGKePrzw0MfECHvemgDJBetdAsxmBLLpuBDXkGwS7LHgg1eW0I8nF2GoIGfepUiT6LGdFrH4NUPKMCnNBGBLDVP-UZKUf5t-NXkCzT9tsk4aBRr3JFrE50j3hyqH7T00BlIBp-o0T_nkwJX-B2NqpHlummAV6Xh-4X18zNnA2NIOn6YcQIlCfXpahVF6BWG7iZ6iSWUUhGJoSu7ALDCGwnPlP7E8s47ZLedH9t1raThRlw";

  useEffect(() => {
    initMeeting({
      authToken: auth,
      defaults: {
        audio: false,
        video: false,
      },
    });
  }, [auth]);

  if(!meeting){
    return "Loading Meeting, Please Wait";
  }

  return (
    <DyteProvider value={meeting} showPolls={false}>
        <MeetingViewer />
    </DyteProvider>
  );
}

export default MeetingComponent;