
class CommonFunctions {
getStyle(the_style_data, style, hover, exclude = []){
    if(the_style_data && the_style_data[style]){

        let style_data = the_style_data[style];

        let finalStyle = {};
        let finalHover = {};


        let keys = Object.keys(the_style_data[style]);
        for (let i in keys) {
            if(!exclude.includes(keys[i]))
            {
            finalStyle[keys[i]] = style_data[keys[i]]; 
            }
        }

        finalHover = {
            ...finalStyle, 
            color: finalStyle.hoverColor ? finalStyle.hoverColor : finalStyle.color,
            backgroundColor: finalStyle.hoverBackgroundColor ? finalStyle.hoverBackgroundColor : finalStyle.backgroundColor,
            borderColor: finalStyle.hoverBorderColor ? finalStyle.hoverBorderColor : finalStyle.borderColor,
        }

      return {
        finalStyle, finalHover
      };
    }
      
    return {finalStyle: {}, finalHover: {}}
  }

}

export default new CommonFunctions();