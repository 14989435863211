import { useEffect } from 'react';
import { useDyteClient, useDyteMeeting, DyteProvider } from '@dytesdk/react-web-core';
import { DyteMeeting } from '@dytesdk/react-ui-kit';

const MeetingViewer = () => {
    const { meeting } = useDyteMeeting();
    console.log("meeting b", meeting);

  return (
    <div style={{ height: '700px' }}>
      <DyteMeeting mode="fill" meeting={meeting} showSetupScreen={true}  />
    </div>
    );
}

const MeetingComponentTwo = () => {
  const [meeting, initMeeting] = useDyteClient();

  if(meeting){
    meeting.self.setName("PA Patient");
  }

  let auth = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdJZCI6ImYxZDFjMTU5LWQ0ZGYtNDgzYS05OGRhLTc4NmUxMmMyMTFhNyIsIm1lZXRpbmdJZCI6ImJiYjI1M2Y0LTNhNzAtNDllYi1hNDNkLTBkZmU5YjcyYzFhZiIsInBhcnRpY2lwYW50SWQiOiJhYWFiYjJlZS02YmM2LTQ2MGItYTc3NC1kZWVlMjAzMGY4MzIiLCJwcmVzZXRJZCI6ImM4MTdjOWMwLTYwYjUtNDFmYS04ZTA3LThjNjkwMGJlNWE5MyIsImlhdCI6MTcxMzM3MjExOCwiZXhwIjoxNzIyMDEyMTE4fQ.rmdYCONBm0u6RpW7OymvPa16__QVOegYTPayzKmsZLxDNiFaqR-uoORLhRXlXCe3_OaMTKV_XqdZY-SgqglCViHx6u8QB_y6jOodSqDsRwsqP-aLSBvqTszMybew59viEJq2SgRuW_zkccjjOwLf7tIRBmIo_ilQEdpiE-OWzhNamtNFFlECYNL4j5mg1GLwxQXpWIh4rRb9qQ55nyYZDTA9UxMUS03meqycnNBhV_2r4F6A1XZLrSWx5iuON2P0nHscKodt92eD333UJg0K212jQLRdfIwtNxEFLXiLPyKam-5IcvjaMKvi0U_c5p96uai5o8CAIe79MREC1Ss_bQ";

  console.log("meeting 2", meeting);

  useEffect(() => {
    initMeeting({
      authToken: auth,
      defaults: {
        audio: false,
        video: false,
      },
    });
  }, [auth]);

  if(!meeting){
    return "Loading Meeting, Please Wait";
  }

  return (
    <DyteProvider value={meeting}>
        <MeetingViewer />
    </DyteProvider>
  );
}

export default MeetingComponentTwo;