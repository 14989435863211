import React, { Component } from "react";
import {
  Table,
  Label,
  Message,
  Segment,
  Button,
  Icon,
  Loader
} from "semantic-ui-react";
import moment from "moment";
import commonFunctions from "../../../../commonFunctions";
import api from "../../../../actions/api";

export default class CompanyList extends Component {

  state = {
    admin_style_data: {},
    loading: false
  }

  componentDidMount() {

    this.setState({loading: true});

    api.company.myCompany()
      .then((res) => {
        this.setState({admin_style_data: res.admin_style_data}, () => {
            this.setState({loading: false});
        });
      });
  }

  getStyle(style, hover, exclude = []){

    let result = commonFunctions.getStyle(this.state.admin_style_data,style,hover,exclude);

    if(this.state.hovered == hover){
      return result.finalHover;
    }else{
      return result.finalStyle;
    }
  }

  renderBody() {
    if (this.props.data && this.props.data.length) {
      return this.props.data.map((data) => {
        return (
          <Table.Row
            className="pointer"
            key={data._id}
            onClick={() => {
              this.props.action(data._id);
            }}
          >
            <Table.Cell>PN{data.ref}</Table.Cell>
            <Table.Cell>{data.name}</Table.Cell>
            <Table.Cell>{data.company_info}</Table.Cell>
            <Table.Cell>{data.telephone_number}</Table.Cell>
            <Table.Cell>
              {data.company_address && data.company_address.posttown
                ? data.company_address.posttown
                : "N/A"}
            </Table.Cell>
            <Table.Cell>
              {data.AssignedBenefits.map((item) => {
                let past = "grey";
                if (moment(item.renewal_date).isBefore(moment())) {
                  past = "red";
                }
                return (
                  <Label color={past} key={item._id}>
                    {item.benefit.title}
                  </Label>
                );
              })}
            </Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  render() {

    if(this.state.loading){
      return <Loader active indeterminate size="large">
      <h2>Please wait...</h2>
    </Loader>;
    }

    if (!this.props.data || this.props.data.length === 0) {
      return (
        <Message>
          <Segment textAlign="center" basic>
            <p>There are no records to display</p>
            {this.props.allowAdd && (
              <Button
                icon
                positive
                size="tiny"
                onClick={() => this.props.add()}
              >
                <Icon name="plus" /> Add New Staff Member
              </Button>
            )}
          </Segment>
        </Message>
      );
    }

    return (
      <Table stackable selectable celled style={this.getStyle("companies_table", `companies_table`)}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Reference</Table.HeaderCell>
            <Table.HeaderCell>Company Name</Table.HeaderCell>
            <Table.HeaderCell>Company Info</Table.HeaderCell>
            <Table.HeaderCell>Mobile Number</Table.HeaderCell>
            <Table.HeaderCell>Postal Town</Table.HeaderCell>
            <Table.HeaderCell>Benefits</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderBody()}</Table.Body>
      </Table>
    );
  }
}
