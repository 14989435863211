import React, { Component } from "react";
import { Menu, Image, Dropdown, Icon } from "semantic-ui-react";
import logo from "../../../assets/images/logo.png";
import { withRouter, NavLink } from "react-router-dom";
import {
  FaHome,
  FaUser,
  FaBuilding,
  FaFolder,
  FaFile,
  FaMedkit,
} from "react-icons/fa";
import { Authentication } from "../../../lib/sso";
import _appConfig from "../../../_appConfig";
import commonFunctions from "../../../commonFunctions";

class TopNav extends Component {
  state = {
    activeItem: "",
    style_data: {}
  };

  goto = (data) => {
    this.props.history.push(data);
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  handleLogout = () => {
    Authentication.logout();
  };

  componentDidMount() {
    this.setState({style_data: this.props.style ? {...this.props.style} : {}})
  }

  getStyle(style, hover, exclude = []){

    let result = commonFunctions.getStyle(this.state.style_data,style,hover,exclude);

    if(this.state.hovered == hover){
      return result.finalHover;
    }else{
      return result.finalStyle;
    }
  }

  render() {
    const { activeItem } = this.state;
    let context = Authentication.getUserData();

    let items = [
      {
        name: "home",
        to: "/",
        content: <FaHome size="1.4rem" style={{ margin: "0px" }} />
      },
      {
        name: "company",
        to: "/company",
        content: <><FaBuilding size="1.4rem" /> Company</>
      }, 
      {
        name: "reports",
        to: "/reports",
        content: <><FaFile size="1.4rem" />Reports</>
      },
    ];

    if(!Authentication.can("organisation_clinician.permission")){
      items.push({
        name: "referral",
        to: "/referral",
        content: <><FaFolder size="1.4rem" /> Referrals</>
      });
    }

    return (
      <Menu className="mainNav" stackable style={this.getStyle("nav_bar", `nav_bar`)}>
        <Menu.Item>
          <Image src={this.props.logo ? this.props.logo : logo} className="logo" />
        </Menu.Item>

        {items.map((item, index) => {
          return <Menu.Item
            exact
            as={NavLink}
            to={item.to}
            name={item.name}
            active={activeItem === item.name}
            onClick={this.handleItemClick}

            style={this.getStyle("nav_bar_item", `nav_bar_item_`+index)}
            onMouseEnter={() => this.setState({hovered: `nav_bar_item_`+index})}
            onMouseLeave={() => this.setState({hovered: ""})}

          >
            {item.content}
          </Menu.Item>
        })}

        {/*  

        <Menu.Item
          exact
          as={NavLink}
          to={`/`}
          name={"home"}
          active={activeItem === "home"}
          onClick={this.handleItemClick}
        >
          <FaHome size="1.4rem" style={{ margin: "0px" }} />
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to="/company"
          name={"company"}
          active={activeItem === "company"}
          onClick={this.handleItemClick}
        >
          <FaBuilding size="1.4rem" /> Company
        </Menu.Item>*/}
        {/*  
        <Menu.Item
          as={NavLink}
          to="/employee"
          name={"employee"}
          active={activeItem === "employee"}
          onClick={this.handleItemClick}
        >
          Employees
        </Menu.Item>*/}
        {/*  
        <Menu.Item
          exact
          as={NavLink}
          to="/assessment"
          name={"assessments"}
          active={activeItem === "assessments"}
          onClick={this.handleItemClick}
        >
          <FaMedkit size="1.4rem" /> Health Journeys
        </Menu.Item>*/}
        {/*  
        {!Authentication.can("organisation_clinician.permission") && (
          <Menu.Item
            exact
            as={NavLink}
            to="/referral"
            name={"referral"}
            active={activeItem === "referral"}
            onClick={this.handleItemClick}
          >
            <FaFolder size="1.4rem" /> Referrals
          </Menu.Item>
        )}*/}
        {/* {Authentication.can("fileManager.view") && ( */}
        {/*  
          <Menu.Item
            exact
            as={NavLink}
            to="/reports"
            name={"reports"}
            active={activeItem === "reports"}
            onClick={this.handleItemClick}
          >
            <FaFile size="1.4rem" />
            Reports
          </Menu.Item>
        {/* )} */}
        {/*  */}

        <Menu.Menu position={"right"}>
          <Dropdown item icon={<FaUser />}>
            <Dropdown.Menu>
              <Dropdown.Header>
                {context.firstName} {context.lastName}
              </Dropdown.Header>
              <Dropdown.Item as={NavLink} to={`/employee/${context.sub}`}>
                <Icon name="user" />
                My Profile
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.handleLogout()}>
                <Icon name="sign out" />
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    );
  }
}
export default withRouter(TopNav);
