import React, { Component } from "react";
import { Menu, Icon, Header } from "semantic-ui-react";
import { Authentication } from "../../../lib/sso";
import { withRouter } from "react-router-dom";
import BuildNumber from "../../../_buildNumber";
import Company_Receiver from "../contexts/company_context/Company_Receiver";
import commonFunctions from "../../../commonFunctions";

class CompanyFooter extends Component {
  state = {
    activeItem: "",
    style_data: {}
  };

  componentDidUpdate(prevProps, prevState) {}

  goto = (data) => {
    this.props.history.push(data);
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  handleLogout = () => {
    Authentication.logout();
  };

  componentDidMount() {
    this.setState({style_data: this.props.style ? {...this.props.style} : {}})
  }

  getStyle(style, hover, exclude = []){

    let result = commonFunctions.getStyle(this.state.style_data,style,hover,exclude);

    if(this.state.hovered == hover){
      return result.finalHover;
    }else{
      return result.finalStyle;
    }
  }

  render() {
    // let context = Authentication.getUserData();

    let fText = "";

    if (Authentication.can("navigator_admin.permission")) {
      fText = "Navigator Admin: ";
    } else if (Authentication.can("navigator_case_manager.permission")) {
      fText = "Nurse Manager: ";
    } else if (Authentication.can("organisation_case_manager.permission")) {
      fText = "Organisation Case Manager: ";
    } else if (Authentication.can("organisation_admin.permission")) {
      fText = "Organisation Admin: ";
    } else if (Authentication.can("organisation_clinician.permission")) {
      fText = "Clinician Admin: ";
    }

    return (
      <Menu className="footer" fixed="bottom" style={this.getStyle("footer_bar", `footer_bar`)}>
        <Menu.Item>
          <Header as={"h2"}>
            {fText}
            {this.props.company.name}
          </Header>
        </Menu.Item>
        {/* <Dropdown item icon="user" className="menuDropdown">
          <Dropdown.Menu>
            <Dropdown.Header>
              {context.firstName} {context.lastName}
            </Dropdown.Header>
            <Dropdown.Item onClick={() => this.handleLogout()}>
              <Icon name="sign out" />
              Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

        <Menu.Item position="right">
          <Icon name="bug" />
          Build: #{BuildNumber}
        </Menu.Item>
      </Menu>
    );
  }
}
let Footer = Company_Receiver(CompanyFooter, (company) => {
  return {
    company,
  };
});

export default withRouter(Footer);
