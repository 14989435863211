import Axios from "axios";
import AppConfig from "../_appConfig";
import microsoft_bookings from "./microsoft_bookings";

const server = AppConfig.api_server;
const quickObjectToQueryString = (query) => {
  return (
    "?" +
    Object.keys(query)
      .map((key) =>
        key === "filters"
          ? key + "=" + JSON.stringify(query[key])
          : key + "=" + query[key]
      )
      .join("&")
  );
};

function applyHeaders(extra = {}) {
  return {
    headers: {
      Authorization:
        "Bearer " +
        localStorage.getItem(AppConfig.localStoragePrefix + "access_token"),
    },
    ...extra,
  };
}

export default {
  user: {
    userLookup: (searchQuery) =>
      Axios.get(
        server + "/user/lookup",
        applyHeaders({
          params: { search: searchQuery },
        })
      ).then((res) => res.data),
    reset_password: (staffID, data) =>
      Axios.post(
        server + `/user/password_reset/${staffID}`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    password_policy: () =>
      Axios.get(server + `/../authentication/password_policy`).then(
        (res) => res.data
      ),
  },
  forms: {
    adjustOrder: (id, data) =>
      Axios.patch(
        server + `/forms/${id}/adjustOrder`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    getCompanyForms: (id) =>
      Axios.get(server + `/forms/companyForms/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    addField: (id, data) =>
      Axios.post(server + `/forms/${id}/addField`, data, applyHeaders()).then(
        (res) => res.data
      ),
    addCompanyForm: (id, data) =>
      Axios.post(
        server + `/forms/${id}/addCompanyForm`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    editCompanyForm: (id, id2, data) =>
      Axios.patch(
        server + `/forms/${id}/editCompanyForm/${id2}`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    removeCompanyForm: (id, id2) =>
      Axios.delete(
        server + `/forms/${id}/removeCompanyForm/${id2}`,
        applyHeaders()
      ).then((res) => res.data),
    editField: (id, id2, data) =>
      Axios.patch(
        server + `/forms/${id}/editField/${id2}`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    removeField: (id, id2) =>
      Axios.delete(
        server + `/forms/${id}/removeField/${id2}`,
        applyHeaders()
      ).then((res) => res.data),
    getFieldTypes: () =>
      Axios.get(server + `/forms/getFieldTypes`, applyHeaders()).then(
        (res) => res.data
      ),
  },
  company: {
    getAllClinicians: () =>
      Axios.get(server + "/company/getAllClinicians", applyHeaders()).then(
        (res) => res.data
      ),
    editCompanyLogo: (id, data) =>
      Axios.patch(
        server + `/company/${id}/editCompanyLogo`,
        data,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data),
    myCompany: () =>
      Axios.get(server + `/company/mycompany`, applyHeaders()).then(
        (res) => res.data
      ),
    getAll: () =>
      Axios.get(server + "/company", applyHeaders()).then((res) => res.data),
    getAllDropdown: () =>
      Axios.get(server + "/company/dropdown_list", applyHeaders()).then(
        (res) => res.data
      ),
    getStatuses: (id) =>
      Axios.get(server + `/company/${id}/statuses`, applyHeaders()).then(
        (res) => res.data
      ),
    addCompanyTypography: (id, data) =>
      Axios.post(server + `/company/${id}/addCompanyTypography`, data, applyHeaders()).then(
        (res) => res.data
      ),
    editCompanyTypography: (company, id, data) =>
      Axios.patch(server + `/company/${company}/editCompanyTypography/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    getAssessments: (id) =>
      Axios.get(server + `/company/${id}/assessments`, applyHeaders()).then(
        (res) => res.data
      ),
    getAPPSteps: (id) =>
      Axios.get(server + `/company/${id}/getAPPSteps`, applyHeaders()).then(
        (res) => res.data
      ),
    getDash: (id) =>
      Axios.get(server + `/company/${id}/dash`, applyHeaders()).then(
        (res) => res.data
      ),
    getAllPaged: (query) =>
      Axios.get(
        server + `/company/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    getCompany: (id) =>
      Axios.get(server + `/company/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    companyLookup: (searchQuery) =>
      Axios.get(
        server + "/company/lookup",
        applyHeaders({
          params: { search: searchQuery },
        })
      ).then((res) => res.data),
    addCompany: (data) =>
      Axios.post(server + "/company", data, applyHeaders()).then(
        (res) => res.data
      ),
    addEmployee: (id, data) =>
      Axios.post(
        server + `/company/${id}/employees`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    addAssessmentTest: (id, data) =>
      Axios.post(
        server + `/company/${id}/addAssessmentTest`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    addTest: (id, data) =>
      Axios.post(
        server + `/company/${id}/addAssessmentTest`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    getManagedCompanyEmployeesDropdown: (id) =>
      Axios.get(
        server + `/company/managed/employees/dropdown`,
        applyHeaders()
      ).then((res) => res.data),
    getCompanyEmployeesDropdown: (id) =>
      Axios.get(
        server + `/company/${id}/employees/dropdown`,
        applyHeaders()
      ).then((res) => res.data),
    getCompanyAdminDropdown: (id) =>
      Axios.get(
        server + `/company/${id}/employees/admins/dropdown`,
        applyHeaders()
      ).then((res) => res.data),
    getCompanyEmployees: (id) =>
      Axios.get(server + `/company/${id}/employees`, applyHeaders()).then(
        (res) => res.data
      ),
    getCompanyEmployeesPaged: (id, query) =>
      Axios.get(
        server +
          `/company/${id}/employees/paged` +
          quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    listAssessmentsPaged: (id, query) =>
      Axios.get(
        server +
          `/company/${id}/assessments/paged` +
          quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    listReferralsPaged: (id, query) =>
      Axios.get(
        server +
          `/company/${id}/referrals/paged` +
          quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    listTestsPaged: (id, query) =>
      Axios.get(
        server + `/company/${id}/tests/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    listReferralsList: (id) =>
      Axios.get(server + `/company/${id}/referrals/list`, applyHeaders()).then(
        (res) => res.data
      ),
    listReferralsReAssignList: (id) =>
      Axios.get(
        server + `/company/${id}/referrals/reassignlist`,
        applyHeaders()
      ).then((res) => res.data),


    syncTemplates: (data, id) =>
      Axios.patch(server + `/company/${id}/syncTemplates`, data, applyHeaders()).then(
        (res) => res.data
      ),

    editCompany: (data, id) =>
      Axios.patch(server + `/company/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    addCompanyAPPStep: (data, id) =>
      Axios.post(
        server + `/company/${id}/addAPPStep`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    editCompanyAPPStep: (data, id) =>
      Axios.patch(
        server + `/company/${id}/editAPPStep`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    benefits: {
      assignBenefit: (id, data) =>
        Axios.post(
          server + `/company/${id}/benefit/assign`,
          data,
          applyHeaders()
        ).then((res) => res.data),
      assignBenefitToUser: (id, data) =>
        Axios.post(
          server + `/company/${id}/benefit/assign_to_user`,
          data,
          applyHeaders()
        ).then((res) => res.data),
      getBenefit: (id, id2) =>
        Axios.get(
          server + `/company/${id}/benefit/${id2}`,
          applyHeaders()
        ).then((res) => res.data),
      getBenefits: (id) =>
        Axios.get(server + `/company/${id}/benefit`, applyHeaders()).then(
          (res) => res.data
        ),
      getBenefitsPaged: (id, query) =>
        Axios.get(
          server +
            `/company/${id}/benefit/paged` +
            quickObjectToQueryString(query),
          applyHeaders()
        ).then((res) => res.data),
      updateAssignedBenefit: (id, id2, data) =>
        Axios.patch(
          server + `/company/${id}/benefit/${id2}`,
          data,
          applyHeaders()
        ).then((res) => res.data),
    },
    employeeImporter: {
      export_errors: (company_id, data) => {
        return Axios.patch(
          server + `/company/${company_id}/employees/importer/export_errors`,
          data,
          applyHeaders()
        ).then((res) => res.data);
      },
      create: (company_id, file) => {
        let data = new FormData();
        data.append("file", file);
        return Axios.post(
          server + `/company/${company_id}/employees/importer`,
          data,
          applyHeaders({ "Content-Type": "multipart/form-data" })
        ).then((res) => res.data);
      },
      setFieldMapping: (company_id, import_id, data) => {
        return Axios.patch(
          server +
            `/company/${company_id}/employees/importer/${import_id}/set_fields`,
          data,
          applyHeaders()
        ).then((res) => res.data);
      },
      start: (company_id, import_id) => {
        return Axios.patch(
          server +
            `/company/${company_id}/employees/importer/${import_id}/import`,
          {},
          applyHeaders()
        ).then((res) => res.data);
      },
      status: (company_id, import_id) => {
        return Axios.get(
          server +
            `/company/${company_id}/employees/importer/${import_id}/status`,
          applyHeaders()
        ).then((res) => res.data);
      },
    },
    notices: {
      add: (company_id, data) =>
        Axios.post(
          server + `/company/${company_id}/notices`,
          data,
          applyHeaders()
        ).then((res) => res.data),
      edit: (company_id, id, data) =>
        Axios.patch(
          server + `/company/${company_id}/notices/${id}`,
          data,
          applyHeaders()
        ).then((res) => res.data),
      listPaged: (company_id, query) =>
        Axios.get(
          server +
            `/company/${company_id}/notices/paged` +
            quickObjectToQueryString(query),
          applyHeaders()
        ).then((res) => res.data),
      findOne: (company_id, id) =>
        Axios.get(
          server + `/company/${company_id}/notices/${id}`,
          applyHeaders()
        ).then((res) => res.data),
    },
    getClinicians: (company_id) =>
      Axios.get(
        server + `/company/${company_id}/clinicians`,
        applyHeaders()
      ).then((res) => res.data),
    legal_docs: {
      add: (data, company_id) =>
        Axios.post(
          server + `/company/${company_id}/legal_docs`,
          data,
          applyHeaders({ "Content-Type": "multipart/form-data" })
        ).then((res) => res.data),
      getCurrent: (company_id) =>
        Axios.get(server + `/company/${company_id}/legal_docs/current`).then(
          (res) => res.data
        ),
      allPaged: (company_id, query) =>
        Axios.get(
          server +
            `/company/${company_id}/legal_docs/paged` +
            quickObjectToQueryString(query),
          applyHeaders()
        ).then((res) => res.data),
    },
  },
  team: {
    add: (data) =>
      Axios.post(server + `/team/`, data, applyHeaders()).then(
        (res) => res.data
      ),
    getTeamMembers: () =>
      Axios.get(server + `/team/`, applyHeaders()).then((res) => res.data),
    getCaseManagers: () =>
      Axios.get(server + `/team/cm`, applyHeaders()).then((res) => res.data),

    getNurseManagers: () =>
      Axios.get(server + `/team/nurses`, applyHeaders()).then(
        (res) => res.data
      ),
    getTeamMembersPaged: (query) =>
      Axios.get(
        server + `/team/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    getMember: (id) =>
      Axios.get(server + `/team/${id}`, applyHeaders()).then((res) => res.data),
    editMember: (id, data) =>
      Axios.patch(server + `/team/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    listPermission: () =>
      Axios.get(server + "/team/permissions", applyHeaders()).then(
        (res) => res.data
      ),
    notes: {
      add: (id, data) =>
        Axios.post(server + `/team/${id}/notes`, data, applyHeaders()).then(
          (res) => res.data
        ),
      all: (id) =>
        Axios.get(server + `/team/${id}/notes/`, applyHeaders()).then(
          (res) => res.data
        ),
      allPaged: (id, query) =>
        Axios.get(
          server + `/team/${id}/notes/paged` + quickObjectToQueryString(query),
          applyHeaders()
        ).then((res) => res.data),
      show: (id, id2) =>
        Axios.get(server + `/team/${id}/notes/${id2}`, applyHeaders()).then(
          (res) => res.data
        ),
      update: (id, id2, data) =>
        Axios.patch(
          server + `/team/${id}/notes/${id2}`,
          data,
          applyHeaders()
        ).then((res) => res.data),
    },
  },
  benefits: {
    add: (data) =>
      Axios.post(server + `/benefits/`, data, applyHeaders()).then(
        (res) => res.data
      ),
    list: (id) =>
      Axios.get(server + `/benefits/list/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    all: () =>
      Axios.get(server + `/benefits/`, applyHeaders()).then((res) => res.data),
    allPaged: (query) =>
      Axios.get(
        server + `/benefits/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    show: (id) =>
      Axios.get(server + `/benefits/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    getCompanies: (id) =>
      Axios.get(server + `/benefits/${id}/companies`, applyHeaders()).then(
        (res) => res.data
      ),
    update: (id, data) =>
      Axios.patch(server + `/benefits/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
  },
  services: {
    add: (data) =>
      Axios.post(server + `/service/`, data, applyHeaders()).then(
        (res) => res.data
      ),
    list: () =>
      Axios.get(server + `/service/list`, applyHeaders()).then(
        (res) => res.data
      ),
    all: () =>
      Axios.get(server + `/service/`, applyHeaders()).then((res) => res.data),
    allPaged: (query) =>
      Axios.get(
        server + `/service/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    findOne: (id) =>
      Axios.get(server + `/service/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    update: (id, data) =>
      Axios.patch(server + `/service/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    adjustOrder: (id, data) =>
      Axios.patch(
        server + `/service/${id}/adjustOrder`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    uploadFile: (id, data) => {
      let formData = new FormData();
      let keys = Object.keys(data);
      for (let i in keys) {
        formData.append(keys[i], data[keys[i]]);
      }

      return Axios.patch(
        server + `/service/${id}/file_upload`,
        formData,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data);
    },
    getFilelink: (id) =>
      Axios.get(server + `/service/${id}/link`, applyHeaders()).then(
        (res) => res.data
      ),
  },
  templates: {
    add: (data) => {
      let formData = new FormData();
      let keys = Object.keys(data);
      for (let i in keys) {
        formData.append(keys[i], data[keys[i]]);
      }

      return Axios.post(
        server + `/template/`,
        formData,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data);
    },
    getOne: (id) =>
      Axios.get(server + `/template/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    all: () =>
      Axios.get(server + `/template/`, applyHeaders()).then((res) => res.data),
    allPaged: (query) =>
      Axios.get(
        server + `/template/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    update: (id, data) =>
      Axios.patch(server + `/template/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    getDefaultLinks: () =>
      Axios.get(server + `/template/default_links`, applyHeaders()).then(
        (res) => res.data
      ),
  },
  system_legal_docs: {
    add: (data) =>
      Axios.post(
        server + `/system_legal_docs`,
        data,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data),
    getCurrent: () =>
      Axios.get(server + `/system_legal_docs/current`).then((res) => res.data),
    allPaged: (query) =>
      Axios.get(
        server + `/system_legal_docs/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
  },
  help_categories: {
    add: (data) =>
      Axios.post(server + `/help_category`, data, applyHeaders()).then(
        (res) => res.data
      ),
    all: () =>
      Axios.get(server + `/help_category`, applyHeaders()).then(
        (res) => res.data
      ),
    allPaged: (query) =>
      Axios.get(
        server + `/help_category/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    show: (id) =>
      Axios.get(server + `/help_category/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    update: (id, data) =>
      Axios.patch(server + `/help_category/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
  },
  mobile_splash_screens: {
    add: (data) =>
      Axios.post(
        server + `/splash_screens`,
        data,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data),
    allPaged: (query) =>
      Axios.get(
        server + `/splash_screens/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    show: (id) =>
      Axios.get(server + `/splash_screens/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    update: (id, data) =>
      Axios.patch(
        server + `/splash_screens/${id}`,
        data,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data),
  },
  health_notices: {
    add: (data) =>
      Axios.post(server + `/health_notices`, data, applyHeaders()).then(
        (res) => res.data
      ),
    edit: (id, data) =>
      Axios.patch(server + `/health_notices/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    list: () =>
      Axios.get(server + `/health_notices`, applyHeaders()).then(
        (res) => res.data
      ),
    listPaged: (query) =>
      Axios.get(
        server + `/health_notices/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    findOne: (id) =>
      Axios.get(server + `/health_notices/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
  },
  referral: {
    getAll: () =>
      Axios.get(server + "/referral", applyHeaders()).then((res) => res.data),
    uploadCaseFile: (id, data) => {
      let formData = new FormData();
      let keys = Object.keys(data);
      for (let i in keys) {
        formData.append(keys[i], data[keys[i]]);
      }

      return Axios.patch(
        server + `/referral/${id}/file_upload`,
        formData,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data);
    },
    getAllPaged: (query) =>
      Axios.get(
        server + "/referral/paged" + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    getAllCM: () =>
      Axios.get(server + `/referral/cm`, applyHeaders()).then(
        (res) => res.data
      ),
    getAllCMPaged: (query) =>
      Axios.get(
        server + `/referral/cm/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    getReferral: (id) =>
      Axios.get(server + `/referral/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    getReferralFilelink: (id) =>
      Axios.get(server + `/referral/${id}/link`, applyHeaders()).then(
        (res) => res.data
      ),
    getEmployeeReferrals: (id) =>
      Axios.get(server + `/referral/${id}/all`, applyHeaders()).then(
        (res) => res.data
      ),
    getDashStats: () =>
      Axios.get(server + `/referral/dash`, applyHeaders()).then(
        (res) => res.data
      ),
    getAllEmployeeReferralPaged: (id, query) =>
      Axios.get(
        server + `/referral/${id}/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    addReferal: (data) =>
      Axios.post(server + "/referral", data, applyHeaders()).then(
        (res) => res.data
      ),
    editReferal: (id, data) =>
      Axios.patch(server + `/referral/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    uploadReferralFile: (id, data) => {
      let formData = new FormData();
      let keys = Object.keys(data);
      for (let i in keys) {
        formData.append(keys[i], data[keys[i]]);
      }

      return Axios.patch(
        server + `/referral/${id}/file_upload`,
        formData,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data);
    },
    updateStatus: (data, id) =>
      Axios.post(
        server + `/referral/${id}/updateStatus`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    notes: {
      add: (id, data) =>
        Axios.post(server + `/referral/${id}/logs`, data, applyHeaders()).then(
          (res) => res.data
        ),
      getReferralNoteDetails: (id, id2) =>
        Axios.get(server + `/referral/${id}/logs/${id2}`, applyHeaders()).then(
          (res) => res.data
        ),
      edit: (id, id2, data) =>
        Axios.patch(
          server + `/referral/${id}/logs/${id2}`,
          data,
          applyHeaders()
        ).then((res) => res.data),
      listAllPaged: (id, query) =>
        Axios.get(
          server +
            `/referral/${id}/logs/paged` +
            quickObjectToQueryString(query),
          applyHeaders()
        ).then((res) => res.data),
      getReferralNotes: (id) =>
        Axios.get(server + `/referral/${id}/logs`, applyHeaders()).then(
          (res) => res.data
        ),
    },
  },
  bookings: {
    getServices: async (businessId) =>
      Axios.get(
        server + `/microsoft_bookings/calendars/${businessId}/services`,
        applyHeaders()
      ).then((res) => res.data),
    getAvailableSlots: async (businessId, serviceId, date) =>
      Axios.get(
        server +
          `/microsoft_bookings/calendars/${businessId}/services/${serviceId}/availability?date=${date}`,
        applyHeaders()
      ).then((res) => res.data),
    cancelBooking: async (businessId, serviceId, appointmentId) =>
      Axios.delete(
        server +
          `/microsoft_bookings/calendars/${businessId}/services/${serviceId}/appointment/${appointmentId}`,
        applyHeaders()
      ).then((res) => res.data),
  },
  assessment: {
    getAll: () =>
      Axios.get(server + "/assessment", applyHeaders()).then((res) => res.data),
    uploadCaseFile: (id, data) => {
      let formData = new FormData();
      let keys = Object.keys(data);
      for (let i in keys) {
        formData.append(keys[i], data[keys[i]]);
      }

      return Axios.patch(
        server + `/assessment/${id}/file_upload`,
        formData,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data);
    },
    addAssessmentTest: (id, data) =>
      Axios.post(
        server + `/assessment/${id}/addAssessmentTest`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    getAssessmentCompletedBy: (data) =>
      Axios.post(
        server + `/assessment/getAssessmentCompletedBy`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    getTestResults: (id) =>
      Axios.get(
        server + `/assessment/${id}/getTestResults`,
        applyHeaders()
      ).then((res) => res.data),
    getAllPaged: (query) =>
      Axios.get(
        server + "/assessment/paged" + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    getAllCM: () =>
      Axios.get(server + `/assessment/cm`, applyHeaders()).then(
        (res) => res.data
      ),
    getAllCMPaged: (query) =>
      Axios.get(
        server + `/assessment/cm/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    getAssessment: (id) =>
      Axios.get(server + `/assessment/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    notifyPatient: (id) =>
      Axios.post(
        server + `/assessment/${id}/notify_patient`,
        {},
        applyHeaders()
      ).then((res) => res.data),
    generateReport: (id) =>
      Axios.post(
        server + `/assessment/${id}/generate_report`,
        {},
        applyHeaders()
      ).then((res) => res.data),
    listTestsPaged: (id, query) =>
      Axios.get(
        server +
          `/assessment/${id}/tests/paged` +
          quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    getAssessmentFilelink: (id) =>
      Axios.get(server + `/assessment/${id}/link`, applyHeaders()).then(
        (res) => res.data
      ),
    getEmployeeAssessments: (id) =>
      Axios.get(server + `/assessment/${id}/all`, applyHeaders()).then(
        (res) => res.data
      ),
    getDashStats: () =>
      Axios.get(server + `/assessment/dash`, applyHeaders()).then(
        (res) => res.data
      ),
    getAllEmployeeAssessmentsPaged: (id, query) =>
      Axios.get(
        server + `/assessment/${id}/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    addAssessment: (employee) =>
      Axios.post(server + `/assessment/${employee}`, {}, applyHeaders()).then(
        (res) => res.data
      ),
    editAssessment: (id, data) =>
      Axios.patch(server + `/assessment/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    uploadAssessmentFile: (id, data) => {
      let formData = new FormData();
      let keys = Object.keys(data);
      for (let i in keys) {
        formData.append(keys[i], data[keys[i]]);
      }

      return Axios.patch(
        server + `/assessment/${id}/file_upload`,
        formData,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data);
    },
    updateStatus: (data, id) =>
      Axios.post(
        server + `/assessment/${id}/updateStatus`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    notes: {
      add: (id, data) =>
        Axios.post(
          server + `/assessment/${id}/logs`,
          data,
          applyHeaders()
        ).then((res) => res.data),
      getAssessmentNoteDetails: (id, id2) =>
        Axios.get(
          server + `/assessment/${id}/logs/${id2}`,
          applyHeaders()
        ).then((res) => res.data),
      edit: (id, id2, data) =>
        Axios.patch(
          server + `/assessment/${id}/logs/${id2}`,
          data,
          applyHeaders()
        ).then((res) => res.data),
      listAllPaged: (id, query) =>
        Axios.get(
          server +
            `/assessment/${id}/logs/paged` +
            quickObjectToQueryString(query),
          applyHeaders()
        ).then((res) => res.data),
      getAssessmentNotes: (id) =>
        Axios.get(server + `/assessment/${id}/logs`, applyHeaders()).then(
          (res) => res.data
        ),
    },
  },
  files: {
    getAll: () =>
      Axios.get(server + "/fileManager", applyHeaders()).then(
        (res) => res.data
      ),
    getFolder: (id) =>
      Axios.get(server + `/fileManager/folder/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    getFolderPath: (id) =>
      Axios.get(
        server + `/fileManager/folder/route/${id}`,
        applyHeaders()
      ).then((res) => res.data),
    getFilelink: (id) =>
      Axios.get(
        server + `/fileManager/folder/file/${id}/link`,
        applyHeaders()
      ).then((res) => res.data),
    searchFiles: (data) =>
      Axios.post(
        server + `/fileManager/files/search`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    deleteFile: (id) =>
      Axios.delete(server + `/fileManager/files/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    addFolder: (data) =>
      Axios.post(server + "/fileManager/folder/", data, applyHeaders()).then(
        (res) => res.data
      ),
    move: (data) =>
      Axios.post(server + "/fileManager/move", data, applyHeaders()).then(
        (res) => res.data
      ),
    deleteFolder: (id) =>
      Axios.delete(server + `/fileManager/folder/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    renameFolder: (id, data) =>
      Axios.patch(
        server + `/fileManager/folder/${id}`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    uploadFile: (data) => {
      let formData = new FormData();
      let keys = Object.keys(data);
      for (let i in keys) {
        formData.append(keys[i], data[keys[i]]);
      }

      return Axios.post(
        server + `/fileManager/file_upload`,
        formData,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data);
    },
  },
  case: {
    getAll: () =>
      Axios.get(server + "/case", applyHeaders()).then((res) => res.data),
    uploadCaseFile: (id, data) => {
      let formData = new FormData();
      let keys = Object.keys(data);
      for (let i in keys) {
        formData.append(keys[i], data[keys[i]]);
      }

      return Axios.patch(
        server + `/case/${id}/file_upload`,
        formData,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data);
    },
    assignMultiCaseManager: (data) =>
      Axios.post(server + "/case/assignMulti", data, applyHeaders()).then(
        (res) => res.data
      ),
    getAllPaged: (query) =>
      Axios.get(
        server + "/case/paged" + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    getAllCM: () =>
      Axios.get(server + `/case/cm`, applyHeaders()).then((res) => res.data),
    getAllCMPaged: (query) =>
      Axios.get(
        server + `/case/cm/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    getDashStats: () =>
      Axios.get(server + `/case/dash`, applyHeaders()).then((res) => res.data),
    addCase: (data) =>
      Axios.post(server + "/case", data, applyHeaders()).then(
        (res) => res.data
      ),
    editCase: (id, data) =>
      Axios.patch(server + `/case/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    getCase: (id) =>
      Axios.get(server + `/case/${id}`, applyHeaders()).then((res) => res.data),
    addCaseNote: (id, data) =>
      Axios.post(server + `/case/${id}/logs`, data, applyHeaders()).then(
        (res) => res.data
      ),
    editCaseNote: (id, id2, data) =>
      Axios.patch(
        server + `/case/${id}/logs/${id2}`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    getCaseNotes: (id) =>
      Axios.get(server + `/case/${id}/logs`, applyHeaders()).then(
        (res) => res.data
      ),
    getCaseNoteDetails: (id, id2) =>
      Axios.get(server + `/case/${id}/logs/${id2}`, applyHeaders()).then(
        (res) => res.data
      ),
    markLogComplete: (id, data) =>
      Axios.get(server + `/case/${id}/logs/mark`, data, applyHeaders()).then(
        (res) => res.data
      ),
  },
  employee: {
    getMySelf: () =>
      Axios.get(server + `/myself/get_user`, applyHeaders()).then(
        (res) => res.data
      ),
    resendOnboarding: (id) =>
      Axios.get(server + `/employee/${id}/resendOnboarding`, applyHeaders()).then(
        (res) => res.data
      ),
    resetPassword: (id) =>
      Axios.get(server + `/employee/${id}/sendResetPassword`, applyHeaders()).then(
        (res) => res.data
      ),
    getEmployee: (id) =>
      Axios.get(server + `/employee/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    forgetUser: (id) =>
      Axios.post(server + `/employee/${id}/forgetUser`,{}, applyHeaders()).then(
        (res) => res.data
      ),
    startMasqueradeSession: (userId) =>
      Axios.post(
        server + `/employee/${userId}/start_masquerade`,
        {},
        applyHeaders()
      ).then((res) => res.data),
    getLogsPaged: (id, query) =>
      Axios.get(
        server + `/employee/${id}/logs/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    addLog: (id, data) =>
      Axios.post(server + `/employee/${id}/logs`, data, applyHeaders()).then(
        (res) => res.data
      ),
    editLog: (id, id2, data) =>
      Axios.patch(
        server + `/employee/${id}/logs/${id2}`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    editEmployee: (data, id) =>
      Axios.patch(server + `/employee/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    addReferal: (id, data) =>
      Axios.post(
        server + `/employee/${id}/referral`,
        data,
        applyHeaders()
      ).then((res) => res.data),
    healthData: {
      addWeight: (id, data) =>
        Axios.post(
          server + `/employee/${id}/health/bmi`,
          { data },
          applyHeaders()
        ).then((res) => res.data),
      getWeight: (id) =>
        Axios.get(server + `/employee/${id}/health/bmi`, applyHeaders()).then(
          (res) => res.data
        ),
      addBP: (id, data) =>
        Axios.post(
          server + `/employee/${id}/health/bp`,
          { data },
          applyHeaders()
        ).then((res) => res.data),
      getBP: (id) =>
        Axios.get(server + `/employee/${id}/health/bp`, applyHeaders()).then(
          (res) => res.data
        ),
    },
    listPermission: () =>
      Axios.get(server + `/employee/permissions`, applyHeaders()).then(
        (res) => res.data
      ),
  },
  emails: {
    getAll: () =>
      Axios.get(server + "/email", applyHeaders()).then((res) => res.data),
    getEmail: (id) =>
      Axios.get(server + `/email/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    addEmail: (data) =>
      Axios.post(server + "/email", data, applyHeaders()).then(
        (res) => res.data
      ),
    editEmail: (data, id) =>
      Axios.patch(server + `/email/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
  },
  email_automation: {
    getAll: () =>
      Axios.get(server + "/email_automation", applyHeaders()).then(
        (res) => res.data
      ),
    getAutomation: (id) =>
      Axios.get(server + `/email_automation/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    /*
    addAutomation: (data) =>
      Axios.post(server + "/email_automation", data, applyHeaders()).then(
        (res) => res.data
      ),*/
    editAutomation: (data, id) =>
      Axios.patch(
        server + `/email_automation/${id}`,
        data,
        applyHeaders()
      ).then((res) => res.data),
  },
  settings: {
    all: () =>
      Axios.get(server + `/settings`, applyHeaders()).then((res) => res.data),
    syncC1: () =>
      Axios.post(server + `/settings/syncC1`, {}, applyHeaders()).then(
        (res) => res.data
      ),
    formLists: () =>
      Axios.get(server + `/settings/formlists`, applyHeaders()).then(
        (res) => res.data
      ),
    update: (data) =>
      Axios.patch(server + `/settings`, data, applyHeaders()).then(
        (res) => res.data
      ),
  },
  home: {
    getDashStats: () =>
      Axios.get(server + `/home/dash`, applyHeaders()).then((res) => res.data),
  },
  reports: {
    hj_progress: (query) =>
      Axios.get(
        server + `/reports/hj_progress`+ quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    hj_form: (query) =>
      Axios.get(
        server + `/reports/hj_form`+ quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    referral_report: (query) =>
      Axios.get(
        server + `/reports/referral_report` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    service_report: (query) =>
      Axios.get(
        server + `/reports/service_report` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    case_report: (query) =>
      Axios.get(
        server + `/reports/case_report` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    employee_report: (query) =>
      Axios.get(
        server + `/reports/employee_report` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
  },
  resource_categories: {
    get: (id) =>
      Axios.get(server + `/resource_categories/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    list: () =>
      Axios.get(server + `/resource_categories`, applyHeaders()).then(
        (res) => res.data
      ),
    listPaged: (query) =>
      Axios.get(
        server + `/resource_categories/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    add: (data) =>
      Axios.post(server + `/resource_categories`, data, applyHeaders()).then(
        (res) => res.data
      ),
    edit: (id, data) =>
      Axios.patch(
        server + `/resource_categories/${id}`,
        data,
        applyHeaders()
      ).then((res) => res.data),
  },
  resources: {
    get: (id) =>
      Axios.get(server + `/resources/${id}`, applyHeaders()).then(
        (res) => res.data
      ),
    list: () =>
      Axios.get(server + `/resources`, applyHeaders()).then((res) => res.data),
    listPaged: (query) =>
      Axios.get(
        server + `/resources/paged` + quickObjectToQueryString(query),
        applyHeaders()
      ).then((res) => res.data),
    add: (data) =>
      Axios.post(server + `/resources`, data, applyHeaders()).then(
        (res) => res.data
      ),
    edit: (id, data) =>
      Axios.patch(server + `/resources/${id}`, data, applyHeaders()).then(
        (res) => res.data
      ),
    uploadFile: (id, data) => {
      let formData = new FormData();
      let keys = Object.keys(data);
      for (let i in keys) {
        formData.append(keys[i], data[keys[i]]);
      }

      return Axios.patch(
        server + `/resources/${id}/file_upload`,
        formData,
        applyHeaders({ "Content-Type": "multipart/form-data" })
      ).then((res) => res.data);
    },
    getFilelink: (id) =>
      Axios.get(server + `/resources/${id}/link`, applyHeaders()).then(
        (res) => res.data
      ),
    adjustOrder: (id, data) =>
      Axios.patch(
        server + `/resources/${id}/adjustOrder`,
        data,
        applyHeaders()
      ).then((res) => res.data),
  },
  microsoft_bookings,
};
