export default {
  api_server: "/api",
  localStoragePrefix: "navigator-dev__",
  postcoder_api_key: "PCW45-12345-12345-1234X",
  postcoder_identifier: "Testing",
  auth: {
    clientId: "5f3fa01449bd087b37c535e5",
    loginServer: "https://dev.patientnavigator.co.uk",
    sessionTimeout: 420
  },
};
