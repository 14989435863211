import React, { Component } from "react";
import { Menu, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FaListUl, FaPlus } from "react-icons/fa";
import { Authentication } from "../../../lib/sso";
import api from "../../../actions/api";
import commonFunctions from "../../../commonFunctions";

export default class CompanyMenu extends Component {
  state = {
    activeItem: "dash",
    company: {},
    style_data: {}
  };

  componentDidMount() {
    this.setState({style_data: this.props.style ? {...this.props.style} : {}})
    this.setState({company: this.props.company ? this.props.company : {}})
  }

  /*
  componentDidMount() {
    api.company.myCompany().then((company) => {
      this.setState({ company });
    });
  } */

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  getStyle(style, hover, exclude = []){
    let result = commonFunctions.getStyle(this.state.style_data,style,hover,exclude);

    if(this.state.hovered == hover){
      return result.finalHover;
    }else{
      return result.finalStyle;
    }
  }

  render() {
    const { activeItem } = this.state;

    let items = [
      {
        name: "list",
        to: "/company/list",
        content: <><FaListUl /> List</>
      },
      Authentication.can("navigator_admin.permission") && {
        name: "add",
        to: "/company/add",
        content: <><FaPlus /> Add</>
      },
    ];

    return (
      <Menu className="subMenu" style={this.getStyle("companies_menu", `companies_menu`)}>
        <Menu.Item>
          <Header>Company Menu</Header>
        </Menu.Item>

        {items.map((item, index) => {
          return <Menu.Item
            exact
            as={Link}
            to={item.to}
            name={item.name}
            active={activeItem === item.name}
            onClick={this.handleItemClick}

            style={this.getStyle("companies_menu_item", `companies_menu_item_`+index)}
            onMouseEnter={() => this.setState({hovered: `companies_menu_item_`+index})}
            onMouseLeave={() => this.setState({hovered: ""})}

          >
            {item.content}
          </Menu.Item>
        })}

        {/*

        <Menu.Item
          as={Link}
          to={"/company/list"}
          name="list"
          active={activeItem === "list"}
          onClick={this.handleItemClick}
        >
          <FaListUl />
          List
        </Menu.Item>

        {Authentication.can("navigator_admin.permission") && (
          <Menu.Item
            as={Link}
            to={"/company/add"}
            name="add"
            active={activeItem === "add"}
            onClick={this.handleItemClick}
          >
            <FaPlus />
            Add
          </Menu.Item>
        )}
        {Authentication.can("organisation_admin.permission") &&
          this.state.company &&
          this.state.company.c1h === true && (
            <Menu.Item
              as={Link}
              to={"/company/add"}
              name="add"
              active={activeItem === "add"}
              onClick={this.handleItemClick}
            >
              <FaPlus />
              Add
            </Menu.Item>
          )}
            */}
      </Menu>
    );
  }
}
