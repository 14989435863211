import React, { Component } from "react";
import TopNav from "./components/topNav";
import Footer from "./components/footer";
import { Route, Switch } from "react-router-dom";
import CompanyProvider from "./contexts/company_context/Company_Provider";
import api from "./actions/api";
import Home from "./pages/home";
import Details from "./pages/details";
import EmployeePages from "./pages/employees";
import CompanyPages from "../_pn/company";
import ReferralPages from "./pages/referrals";
import AssessmentPages from "./pages/assessment";
import ResetPasswordForm from "./components/forms/resetPasswordForm";
import ReportsPages from "../_pn/reports";
import {
  Loader,
} from "semantic-ui-react";

import { Authentication } from "../../lib/sso";

export default class Index extends Component {
  state = {
    userDetails: [],
    site_logo: null,
    admin_style_data: {},
    loading: false,
  };
  
  componentDidMount() {

    this.setState({loading: true});

    api.company.show()
      .then((res) => {
        this.setState({admin_style_data: res.admin_style_data}, () => {
            this.setState({loading: false});
        });

        if(res.site_logo) {
          api.files.getFilelink(res.site_logo).then(f => {
            this.setState({site_logo: f});
          })
        }

        // this.setState({ data: res, loading: false });
      })
      .catch((e) => {

        this.setState({loading: false});
        
        console.log(e);
        // this.setState({ error: true });
      });
  }

  render() {

    if(this.state.loading){
      return <Loader active indeterminate size="large">
      <h2>Please wait...</h2>
    </Loader>;
    }

    return (
      <div className="wrapper companyHolder">
        <CompanyProvider api={api}>
          <TopNav match={this.props.match} history={this.props.history} logo={this.state.site_logo} style={this.state.admin_style_data}/>
          <div className="pageWrapper">
            <Switch>
              {!Authentication.can("organisation_clinician.permission") && (
                <Route path={`/referral`} component={ReferralPages} />
              )}
              {Authentication.can("navigator_admin.permission") ||
                (Authentication.can("organisation_clinician.permission") && (
                  <Route path={`/assessment`} component={AssessmentPages} />
                ))}
              {(Authentication.can("organisation_admin.permission") ||
              Authentication.can("organisation_clinician.permission")) && 
                <Route path={`/reports`} component={ReportsPages} />
              }
              {Authentication.can("organisation_clinician.permission") ||
                (Authentication.can("organisation_admin.permission") && (
                  <Route path={`/assessment`} component={AssessmentPages} />
                ))}
              <Route path={`/company`} component={CompanyPages} />
              <Route path={`/employee`} component={EmployeePages} />
              {Authentication.can("organisation_admin.permission") && (
                <Route
                  path={`/reset_password/:id`}
                  component={ResetPasswordForm}
                />
              )}
              <Route exact path={`/`} component={Home} />
              <Route exact path={`/details`} component={Details} />
            </Switch>
          </div>
          <Footer match={this.props.match} history={this.props.history} style={this.state.admin_style_data} />
        </CompanyProvider>
      </div>
    );
  }
}
